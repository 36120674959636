import {
  V1ResourceResource,
  useListResourcesForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";

const useListResources = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { data, error, isLoading, refetch } = useListResourcesForAccountQuery({
    slug: accountSlug,
    filter: { active: true },
  });

  let resources: V1ResourceResource[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      resources = data?.data;
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    resources,
    resourcesError: error || customError,
    resourcesLoading: isLoading,
    pageMeta,
    resourcesRefetch: refetch,
  };
};

export default useListResources;
