import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useOauth from "@utils/customHooks/useOauth";
import API from "@API";
import EditProject from "./EditProject/EditProject";
import NewProjectDetails from "./CreateProjectMspa/NewProjectDetails/NewProjectDetails";
import ProjectsList from "./ProjectsList/ProjectsList";

export const AccountContext = React.createContext({
  accountId: "",
  accountSlug: "",
  authorizationCode: "",
  planDetails: [],
});

function ProjectsMspa({
  account_slug,
  account_id,
  save_to_cloud_storage_flag,
}) {
  const [authorizationCode] = useOauth();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [fullSalesExecsList, setFullSalesExecsList] = useState(null);
  const [fullPresalesEngineersList, setFullPresalesEngineersList] =
    useState(null);
  const [fullClientList, setFullClientList] = useState(null);
  const [fullTeamsList, setFullTeamsList] = useState(null);
  const [fullTeammateList, setFullTeammateList] = useState(null);
  const [fullTagsList, setFullTagsList] = useState(null);
  const [planDetails, setPlanDetails] = useState([]);

  // When page loads, call API to get Notes and lift it up to the state
  useEffect(() => {
    if (authorizationCode != "") {
      //Get Sales Execs
      API.Get(
        `${apiHost}/${account_slug}/v1/sales-executives?page[size]=100`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        setFullSalesExecsList(data);
      });

      //Get pre-sales engineers
      API.Get(
        `${apiHost}/${account_slug}/v1/presales-engineers?page[size]=100`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        setFullPresalesEngineersList(data);
      });

      // Get clients
      API.Get(
        `${apiHost}/${account_slug}/v1/clients?page[size]=1000`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        setFullClientList(data);
      });

      // Get Teams
      API.Get(
        `${apiHost}/${account_slug}/v1/teams?page[size]=100`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        setFullTeamsList(data);
      });

      // Get Teammates
      API.Get(
        `${apiHost}/${account_slug}/v1/users?page[size]=200`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        setFullTeammateList(data);
      });

      // Get Tags
      API.Get(`${apiHost}/${account_slug}/v1/tags`, authorizationCode).then(
        (response) => {
          const data = response.data.data;
          setFullTagsList(data);
        }
      );

      // Get Questionnairs to determine if questionnaires should be shown
      API.Get(
        `${apiHost}/${account_slug}/v1/questionnaires`,
        authorizationCode
      ).then((response) => {
        const qData = response.data.data;
        let questionnaires = [];
        if (qData && qData !== "") {
          questionnaires = qData;
        }
        API.Get(
          `${apiHost}/v1/accounts/${account_slug}`,
          authorizationCode
        ).then((response) => {
          const data = response.data.data;
          if (questionnaires.length === 0) {
            data.attributes["plan-details"].questionnaires = false;
          }

          setPlanDetails(data.attributes["plan-details"]);
        });
      });
    }
  }, [authorizationCode]);

  const account = {
    id: account_id,
    slug: account_slug,
    authorizationCode,
    planDetails,
  };

  return (
    <main>
      <AccountContext.Provider value={account}>
        <Routes>
          <Route
            exact
            path={"/"}
            element={
              <ProjectsList
                account_slug={account_slug}
                account_id={account_id}
                fullSalesExecsList={fullSalesExecsList}
                fullPresalesEngineersList={fullPresalesEngineersList}
                fullClientList={fullClientList}
                fullTeamsList={fullTeamsList}
                fullTeammateList={fullTeammateList}
                fullTagsList={fullTagsList}
                authorizationCode={authorizationCode}
              />
            }
          />
          <Route
            exact
            path={"/new"}
            element={
              <NewProjectDetails
                account_id={account_id}
                account_slug={account_slug}
                authorizationCode={authorizationCode}
                save_to_cloud_storage_flag={save_to_cloud_storage_flag}
              />
            }
          />
          <Route
            path={"/:project_id/edit"}
            element={
              <EditProject
                account_slug={account_slug}
                account_id={account_id}
              />
            }
          />
        </Routes>
      </AccountContext.Provider>
    </main>
  );
}

export default ProjectsMspa;
