import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import BackButton from "@components/Buttons/BackButton/BackButton";
import SubmitButton from "@components/Buttons/SubmitButton/SubmitButton";
import CardHeader from "@components/CardHeader/CardHeader";
import MultipleLineField from "@components/Forms/MulitpleLineField";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";
import API from "@API";
import useOauth from "@utils/customHooks/useOauth";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { formatUnprocessibleResponse } from "@utils/helperFunctions";
import useUnloadWarning from "@utils/customHooks/useUnloadWarning";

function CustomerSummary({ account_slug, project_id, minimizedSummary }) {
  const [projectStatus, setProjectStatus] = useState("");
  const [executiveSummary, setExecutiveSummary] = useState("");
  const [solutionSummary, setSolutionSummary] = useState("");
  const [ourResponsibilities, setOurResponsibilities] = useState("");
  const [customerResponsibilities, setCustomerResponsibilities] = useState("");
  const [outOfScope, setOutOfScope] = useState("");
  const [submitUrl, setSubmitUrl] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [fieldLabels, setFieldLabels] = useState({
    executive_summary: "Executive Summary",
    solution_summary: "Solution Summary",
    our_responsibilities: "Our Responsibilities",
    client_responsibilities: "Customer Responsibilities",
    out_of_scope: "Out of Scope",
  });
  const [authorizationCode] = useOauth();

  const [errorMessages, setErrorMessages] = useState([]);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setFormDirty] = useState(false);

  useUnloadWarning(isFormDirty);

  useEffect(() => {
    if (authorizationCode != "") {
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (response) => {
          const data = response.data.data;
          setFieldLabels(data.attributes["field-labels"]);
        }
      );

      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}`,
        authorizationCode
      ).then((response) => {
        const data = response.data.data;
        if (data.attributes.status !== null)
          setProjectStatus(data.attributes.status);
        if (data.attributes["executive-summary"] !== null)
          setExecutiveSummary(data.attributes["executive-summary"]);
        if (data.attributes["solution-summary"])
          setSolutionSummary(data.attributes["solution-summary"]);
        if (data.attributes["our-responsibilities"] !== null)
          setOurResponsibilities(data.attributes["our-responsibilities"]);
        if (data.attributes["client-responsibilities"] !== null)
          setCustomerResponsibilities(
            data.attributes["client-responsibilities"]
          );
        if (data.attributes["out-of-scope"] !== null)
          setOutOfScope(data.attributes["out-of-scope"]);
        if (data.links.self !== null) setSubmitUrl(data.links.self);
        setIsLoading(false);
      });
    }
  }, [authorizationCode]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormDirty(false);
    const data = {
      data: {
        type: "projects",
        id: project_id,
        attributes: {
          "executive-summary": executiveSummary,
          "solution-summary": solutionSummary,
          "our-responsibilities": ourResponsibilities,
          "client-responsibilities": customerResponsibilities,
          "out-of-scope": outOfScope,
        },
      },
    };

    API.Patch(submitUrl, data, authorizationCode)
      .then((response) => {
        if (response.status === 200) {
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "summary"));
        setShowFailAlert(true);
      });
  };

  const handleSuccessAlert = () => {
    if (showSuccessAlert === true) {
      return (
        <DismissibleAlert
          variant="info"
          onClose={() => setShowSuccessAlert(false)}
          text="Project Outcomes updated"
        />
      );
    }
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };
  const handleFormChange = (event) => {
    setFormDirty(true);
  };

  return (
    <>
      {handleSuccessAlert()}
      {handleFailAlert()}
      <Card>
        <CardHeader title="Solution Summary and Responsibilites" />
        <Card.Body>
          {isLoading ? (
            <ScopeStackSpinner />
          ) : (
            <Form onSubmit={handleSubmit} onChange={handleFormChange}>
              <MultipleLineField
                label={fieldLabels["executive_summary"]}
                value={executiveSummary}
                onChange={(e) => {
                  setExecutiveSummary(e.target.value);
                }}
                rows={2}
              />
              <MultipleLineField
                label={fieldLabels["solution_summary"]}
                value={solutionSummary}
                onChange={(e) => setSolutionSummary(e.target.value)}
                rows={2}
              />
              {!minimizedSummary && (
                <>
                  <MultipleLineField
                    label={fieldLabels["our_responsibilities"]}
                    value={ourResponsibilities}
                    onChange={(e) => setOurResponsibilities(e.target.value)}
                    rows={2}
                  />
                  <MultipleLineField
                    label={fieldLabels["client_responsibilities"]}
                    value={customerResponsibilities}
                    onChange={(e) =>
                      setCustomerResponsibilities(e.target.value)
                    }
                    rows={2}
                  />
                  <MultipleLineField
                    label={fieldLabels["out_of_scope"]}
                    value={outOfScope}
                    onChange={(e) => setOutOfScope(e.target.value)}
                    rows={2}
                  />
                </>
              )}
              {projectStatus === "building" ? <SubmitButton /> : null}
              <BackButton url={`/projects`} />
            </Form>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default CustomerSummary;
