import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import FormTextField from "@components/Forms/FormTextField";
import VersionHistory from "@components/VersionHistory";
import { useUpdateProjectVersionMutation } from "@generated";
import { handleAlert } from "@utils/helperFunctions";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import UnsavedChangesModal from "@components/UnsavedChangesModal";

function Edit({ project, auditLogPermission, account }) {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const location = useLocation();
  const navigate = useNavigate();
  const [version, setVersion] = useState(location?.state?.version);
  const [description, setDescription] = useState(version?.description || "");
  const [originalDescription, setOriginalDescription] = useState(description);
  const [updateVersion] = useUpdateProjectVersionMutation();
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showLoseChangesConfirmation, setShowLoseChangesConfirmation] =
    useState<boolean>(false);

  const onSave = () => {
    const id = version?.id;
    const data = {
      type: "project-versions",
      id: id,
      attributes: {
        name: version?.name,
        comment: description,
      },
      relationships: {
        project: { data: { type: "projects", id: project?.data?.id } },
      },
    };
    updateVersion({ slug: accountSlug, id: id, body: { data } })
      .unwrap()
      .then(() => {
        setOriginalDescription(description);
        setShowLoseChangesConfirmation(false);
        setSuccessMessage("Version updated successfully!");
        setShowSuccessAlert(true);
      })
      .catch((err) => {
        const error = err?.data?.error;
        setErrorMessage(
          `Version could not be updated due to ${error} error! Please try again later.`
        );
        setShowFailAlert(true);
        setShowLoseChangesConfirmation(false);
      });
  };

  const confirmRevertChanges = () => {
    setDescription(originalDescription);
    navigate(`/projects/${project?.data?.id}/project_versions`, {
      state: { edit: true },
    });
  };

  return (
    <Card>
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <Card.Header className="cardHeaderOverride">
        <div className="headerContainer">
          <div className="actionBtns">
            <Button
              className="btnSeawhite"
              onClick={(e) => {
                if (description !== originalDescription) {
                  setShowLoseChangesConfirmation(true);
                } else {
                  navigate(`/projects/${project?.data?.id}/project_versions`, {
                    state: { edit: true },
                  });
                }
              }}
            >
              Close
            </Button>
            <h2 style={{ marginLeft: "20px", marginTop: "8px" }}>
              {version?.name}
            </h2>
          </div>

          <div className="actionBtns">
            {auditLogPermission === "manage" && (
              <Button
                className={"squareGreenButton"}
                onClick={() => {
                  onSave();
                }}
                disabled={
                  description === "" || description === version?.description
                }
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body style={{ marginTop: "-30px" }}>
        <section>
          <div className="instructionalText bold">About Version</div>
          <FormTextField
            id="expenseDescription"
            label="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            readOnly={false}
            required={false}
            placeholder="Enter version description"
          />
        </section>
        <section>
          <div className="instructionalText bold">Version History</div>
          <VersionHistory
            filter={{
              "context-type": "Project",
              "context-id": project?.data?.id?.toString(),
              before: version?.completedAt,
              after: version?.createdOn,
            }}
            permission={
              auditLogPermission === "manage" &&
              project?.data?.attributes.status === "building"
            }
            account={account}
          />
        </section>
      </Card.Body>
      <UnsavedChangesModal
        show={showLoseChangesConfirmation}
        handleCancel={() => {
          setShowLoseChangesConfirmation(false);
        }}
        handleDontSave={confirmRevertChanges}
        handleSave={onSave}
      />
    </Card>
  );
}

export default Edit;
