import React, { useState, useEffect } from "react";
import OnboardingCards from "../reusable/OnboardingCards";
import { useNavigate } from "react-router";
import logisticsMonitorWifi from "../images/logisticsMonitorWifi.png";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  faCheck,
  faX,
  faPencil,
  faTrashCan,
  faGripDotsVertical,
} from "@fortawesome/pro-solid-svg-icons";
import API from "@utils/API/API";
import {
  formatUnprocessibleResponse,
  handleAlert,
} from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FormNumberField from "@components/Forms/FormNumberField";
import FormTextField from "@components/Forms/FormTextField";
import ControlledDropdown from "@components/Forms/ControlledDropDown";

function Governances({
  governances,
  isLoading,
  account_slug,
  authorizationCode,
  account_id,
  resources,
  setRerender,
}) {
  let navigate = useNavigate();
  const [governancesToDisplay, setGovernancesToDisplay] = useState([]);
  const [errorMessages, setErrorMessages] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showGovernanceConfirmation, setShowGovernanceConfirmation] =
    useState(false);
  const [governanceToDelete, setGovernanceToDelete] = useState({});
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  useEffect(() => {
    let newGovernances = [...governances].map((governance) => {
      governance.userIsEditing = false;
      governance.governanceNameChanged = false;
      governance.detailsChanged = false;
      return governance;
    });
    setGovernancesToDisplay(newGovernances);
  }, [governances, resources, isLoading]);

  window.onpopstate = function (e) {
    setRerender(true);
  };

  useEffect(() => {
    if (!authorizationCode) return;

    API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
      API.Patch(
        `${apiHost}/${account_slug}/v1/users/${response.data.data.id}`,
        {
          data: {
            id: response.data.data.id,
            type: "users",
            attributes: {
              "guided-onboarding": {
                onboarding_status: "governances",
              },
            },
          },
        },
        authorizationCode
      ).then((response) => {
        // console.log(response);
      });
    });
  }, [authorizationCode]);

  const governanceText = (
    <div>
      <p>
        <strong>Project Governance</strong> in ScopeStack ensures that crucial
        tasks are automatically included by offering a systematic approach, such
        as applying a percentage uplift or allocating a fixed number of hours
        per project.
      </p>
      <p>
        This way, you'll always account for aspects like Project Management,
        Documentation, and Contingency planning.
      </p>
      <p>
        Here’s how we start. Customize, add, or remove them to match your
        process.
      </p>
    </div>
  );

  const resourceOptions = resources.map((resource) => (
    <option key={resource.id} value={resource.id}>
      {resource.attributes.name}
    </option>
  ));

  const addGovernance = () => {
    let newGovernance = {
      id: `newGovernance-${Math.floor(Math.random() * 100)}`,
      userIsEditing: true,
      governanceNameChanged: false,
      detailsChanged: false,
      attributes: {
        name: "",
        "calculation-type": "percent_of_total",
        "fixed-hours": "",
        rate: "",
      },
      relationships: {
        account: { data: { type: "accounts", id: account_id } },
        resource: {
          data: {
            type: "resources",
            id: null,
          },
        },
      },
    };
    let newGovernances = [...governancesToDisplay];
    newGovernances.splice(0, 0, newGovernance);
    setGovernancesToDisplay(newGovernances);
  };

  const deleteGovernance = (governanceToDelete) => {
    setShowGovernanceConfirmation(false);
    if (governanceToDelete) {
      if (governanceToDelete.id.includes("newGovernance")) {
        setGovernancesToDisplay(
          governancesToDisplay.filter(
            (governance) => governance.id !== governanceToDelete.id
          )
        );
        return;
      }
      let governanceData = {
        data: {
          type: "governances",
          id: governanceToDelete.id,
        },
      };

      API.Delete(
        `${apiHost}/${account_slug}/v1/governances/${governanceToDelete.id}`,
        governanceData,
        authorizationCode
      )
        .then((res) => {
          setGovernancesToDisplay(
            governancesToDisplay.filter(
              (governance) => governance.id !== governanceToDelete.id
            )
          );
        })
        .catch((err) => {
          setErrorMessages(formatUnprocessibleResponse(err, "governance"));
          setShowFailAlert(true);
        });
    }
  };

  const dragGovernanceEnd = (result) => {
    let isValid = validateDrop(result);
    if (!isValid) return;
    const { source, destination } = result;
    const copyListItems = [...governancesToDisplay];
    const dragItemContent = copyListItems[source.index];
    if (destination.droppableId !== "GovernanceDropZone") return;
    copyListItems.splice(source.index, 1);
    copyListItems.splice(destination.index, 0, dragItemContent);
    let newGovernances = governancesToDisplay.filter(
      (o1) => !copyListItems.some((o2) => o1.id === o2.id)
    );
    setGovernancesToDisplay(newGovernances.concat(copyListItems));
    let governanceToUpdate = dragItemContent;
    governanceToUpdate.type = "governances";
    delete governanceToUpdate.userIsEditing;
    delete governanceToUpdate.governanceNameChanged;
    delete governanceToUpdate.detailsChanged;
    delete governanceToUpdate.newGovernanceName;
    delete governanceToUpdate.links;
    delete governanceToUpdate.newHours;
    delete governanceToUpdate.newRate;
    governanceToUpdate.attributes = {
      name: dragItemContent.attributes.name,
      position:
        destination.index == 0 ? 1 : copyListItems.indexOf(dragItemContent) + 1,
    };
    governanceToUpdate.relationships = {
      account: { data: { id: account_id, type: "accounts" } },
    };
    API.Patch(
      `${apiHost}/${account_slug}/v1/governances/${governanceToUpdate.id}/move-to`,
      { data: governanceToUpdate },
      authorizationCode
    ).catch((err) => {
      setErrorMessages(formatUnprocessibleResponse(err, "governance"));
      setShowFailAlert(true);
    });
  };

  const matchGovernanceToResource = (governance) => {
    let resourceToReturn = [];
    resources.forEach((resource) => {
      if (
        (governance.newResourceId && resource.id == governance.newResourceId) ||
        (!governance.newResourceId &&
          governance.relationships.resource.data &&
          resource.id == governance.relationships.resource.data.id)
      ) {
        resourceToReturn.push(resource);
      }
    });
    return resourceToReturn[0];
  };

  const submitGovernance = (governance) => {
    let resourceId = governance.newResourceId
      ? governance.newResourceId
      : governance.relationships.resource.data.id;
    let submitMethod = "POST";
    let url = `${apiHost}/${account_slug}/v1/governances`;
    let governanceData = {
      data: {
        type: "governances",
        attributes: {
          name: governance.newGovernanceName
            ? governance.newGovernanceName
            : governance.attributes.name,
          rate: governance.newRate
            ? governance.newRate
            : governance.attributes.rate,
          "fixed-hours": governance.newHours
            ? governance.newHours
            : governance.attributes["fixed-hours"],
          "calculation-type": governance.newCalcType
            ? governance.newCalcType
            : governance.attributes["calculation-type"],
        },
        relationships: {
          account: { data: { type: "accounts", id: account_id } },
          resource: {
            data: {
              type: "resources",
              id: resourceId,
            },
          },
        },
      },
    };
    if (!governance.id.includes("newGovernance")) {
      submitMethod = "PATCH";
      url = `${apiHost}/${account_slug}/v1/governances/${governance.id}`;
      governanceData.data.id = governance.id;
    }
    API.Submit(submitMethod, url, governanceData, authorizationCode)
      .then((res) => {
        setGovernancesToDisplay(
          governancesToDisplay.map((gov) => {
            let newGov = res.data.data;
            if (governance.id.includes("newGovernance")) {
              gov.id = newGov.id;
            }
            if (gov.id == newGov.id) {
              gov.userIsEditing = false;
              gov.governanceNameChanged = false;
              gov.detailsChanged = false;
              delete gov.newCalcType;
              gov.attributes.name = newGov.attributes.name;
              gov.attributes["calculation-type"] =
                newGov.attributes["calculation-type"];
              gov.attributes.rate = newGov.attributes.rate;
              gov.attributes["fixed-hours"] = newGov.attributes["fixed-hours"];
              gov.relationships.resource.data.id = governance.newResourceId
                ? governance.newResourceId
                : governance.relationships.resource.data.id;
              delete gov.newResourceId;
            }
            return gov;
          })
        );
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "governance"));
        setShowFailAlert(true);
      });
  };

  const getGovernances = () => {
    if (isLoading) {
      return <SmallSpinner />;
    } else {
      return (
        <>
          <Row
            style={{
              marginLeft: "1px",
              display: "flex",
              alignItems: "center",
              height: "45px",
            }}
            className="cursorPoint whiteBar"
            onClick={() => addGovernance()}
          >
            <Col style={{ paddingLeft: "5px" }}>
              <strong style={{ color: "#418172" }}>
                Add a governance definition +
              </strong>
            </Col>
          </Row>
          <DragDropContext onDragEnd={dragGovernanceEnd}>
            <Droppable droppableId="GovernanceDropZone">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                  {governancesToDisplay.map((governance, index) => (
                    <Draggable
                      key={governance.id}
                      draggableId={governance.id}
                      index={index}
                      isDragDisabled={true}
                    >
                      {(provided) => (
                        <div
                          key={governance.id}
                          id={governance.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {provided.placeholder}
                          <Row
                            key={governance.id}
                            style={
                              governance.userIsEditing
                                ? {
                                    padding: "4px 10px 4px 4px",
                                    marginLeft: "1px",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "175px",
                                  }
                                : {
                                    marginLeft: "1px",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "64px",
                                  }
                            }
                            className={"whiteBar"}
                          >
                            <Col style={{ paddingLeft: "5px" }}>
                              {governance.userIsEditing ? (
                                <Form>
                                  <Row style={{ marginTop: "10px" }}>
                                    <Col>
                                      <FormTextField
                                        type="text"
                                        label="Name"
                                        value={
                                          governance.governanceNameChanged
                                            ? governance.newGovernanceName
                                            : governance.attributes.name
                                        }
                                        placeholder={"Enter governance name"}
                                        onChange={(e) => {
                                          governance.newGovernanceName =
                                            e.target.value;
                                          governance.governanceNameChanged = true;
                                          setGovernancesToDisplay(
                                            governancesToDisplay.map((gov) => {
                                              if (gov.id == governance.id) {
                                                gov.governanceNameChanged = true;
                                                gov.newGovernanceName =
                                                  e.target.value;
                                              }
                                              return gov;
                                            })
                                          );
                                        }}
                                        style={{
                                          marginBottom: "10px",
                                          marginTop: "10px",
                                        }}
                                      />
                                    </Col>
                                    <Col>
                                      <ControlledDropdown
                                        label="Resource"
                                        value={
                                          matchGovernanceToResource(governance)
                                            ? matchGovernanceToResource(
                                                governance
                                              ).id
                                            : "Select resource"
                                        }
                                        options={
                                          governance.id.includes(
                                            "newGovernance"
                                          ) ||
                                          !matchGovernanceToResource(governance)
                                            ? [
                                                <option
                                                  key={"Select resource"}
                                                  value={"Select resource"}
                                                >
                                                  Select resource
                                                </option>,
                                                resourceOptions,
                                              ]
                                            : resourceOptions
                                        }
                                        onChange={(e) => {
                                          governance.newResourceId =
                                            e.target.value;
                                          setGovernancesToDisplay(
                                            governancesToDisplay.map((gov) => {
                                              if (governance.id == gov.id) {
                                                gov.newResourceId =
                                                  e.target.value;
                                              }
                                              return gov;
                                            })
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <ControlledDropdown
                                        label="Calculated by"
                                        defaultValue={
                                          governance.attributes[
                                            "calculation-type"
                                          ]
                                        }
                                        options={[
                                          <option
                                            key={"percent_of_total"}
                                            value="percent_of_total"
                                          >
                                            Percentage
                                          </option>,
                                          <option
                                            key={"fixed_hours"}
                                            value="fixed_hours"
                                          >
                                            Fixed number of hours
                                          </option>,
                                        ]}
                                        onChange={(e) => {
                                          setGovernancesToDisplay(
                                            governancesToDisplay.map((gov) => {
                                              if (governance.id == gov.id) {
                                                gov.newCalcType =
                                                  e.target.value;
                                              }
                                              return gov;
                                            })
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col>
                                      <FormNumberField
                                        label={
                                          (governance.newCalcType &&
                                            governance.newCalcType ==
                                              "percent_of_total") ||
                                          (!governance.newCalcType &&
                                            governance.attributes[
                                              "calculation-type"
                                            ] == "percent_of_total")
                                            ? "Percentage (%)"
                                            : "Hours"
                                        }
                                        placeholder={0.0}
                                        defaultValue={
                                          governance.id.includes(
                                            "newGovernance"
                                          )
                                            ? ""
                                            : (governance.newCalcType &&
                                                governance.newCalcType ==
                                                  "percent_of_total") ||
                                              (!governance.newCalcType &&
                                                governance.attributes[
                                                  "calculation-type"
                                                ] == "percent_of_total")
                                            ? (
                                                parseFloat(
                                                  governance.attributes.rate
                                                ) * 100
                                              ).toFixed(2)
                                            : governance.attributes[
                                                "fixed-hours"
                                              ]
                                        }
                                        onChange={(e) => {
                                          (governance.newCalcType &&
                                            governance.newCalcType ==
                                              "percent_of_total") ||
                                          (!governance.newCalcType &&
                                            governance.attributes[
                                              "calculation-type"
                                            ] == "percent_of_total")
                                            ? (governance.newRate = (
                                                parseFloat(e.target.value) / 100
                                              ).toFixed(2))
                                            : (governance.newHours =
                                                e.target.value);
                                          governance.detailsChanged = true;
                                        }}
                                        step="0.01"
                                      />
                                    </Col>
                                  </Row>
                                </Form>
                              ) : (
                                <div>
                                  <span
                                    style={{
                                      display: "flex",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {/* <FontAwesomeIcon
                                    className="gripIcon"
                                    icon={faGripDotsVertical}
                                  /> */}
                                    <strong>
                                      {governance.attributes.name}
                                    </strong>
                                  </span>

                                  <Row
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Col sm={4} className="governanceDetails">
                                      <strong
                                        style={{
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        Resource:&nbsp;&nbsp;
                                      </strong>

                                      {!matchGovernanceToResource(
                                        governance
                                      ) ? (
                                        <span
                                          style={{
                                            color: "#B90200",
                                          }}
                                        >
                                          Select Resource
                                        </span>
                                      ) : (
                                        matchGovernanceToResource(governance)
                                          .attributes.name
                                      )}
                                    </Col>
                                    <Col sm={5} className="governanceDetails">
                                      <Row>
                                        <Col sm={7}>
                                          {" "}
                                          <strong
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            Calculated by:&nbsp;
                                          </strong>
                                        </Col>
                                        <Col
                                          className={
                                            (governance.newCalcType &&
                                              governance.newCalcType ==
                                                "percent_of_total") ||
                                            (!governance.newCalcType &&
                                              governance.attributes[
                                                "calculation-type"
                                              ] == "fixed_hours")
                                              ? "fixedType"
                                              : "calculationType"
                                          }
                                          style={{
                                            marginLeft: "-45px",
                                            padding: 0,
                                          }}
                                          sm={5}
                                        >
                                          <span>
                                            {(governance.newCalcType &&
                                              governance.newCalcType ==
                                                "percent_of_total") ||
                                            (!governance.newCalcType &&
                                              governance.attributes[
                                                "calculation-type"
                                              ] == "percent_of_total") ? (
                                              <span>
                                                Percent of total hours
                                              </span>
                                            ) : (
                                              <span>Fixed number of hours</span>
                                            )}
                                          </span>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col sm={3} className="governanceDetails">
                                      <strong
                                        style={{ textTransform: "uppercase" }}
                                      >
                                        {(governance.newCalcType &&
                                          governance.newCalcType ==
                                            "percent_of_total") ||
                                        governance.attributes[
                                          "calculation-type"
                                        ] == "percent_of_total"
                                          ? "Percentage"
                                          : "Hours"}
                                      </strong>
                                      :&nbsp;&nbsp;
                                      {(governance.newCalcType &&
                                        governance.newCalcType ==
                                          "percent_of_total") ||
                                      (!governance.newCalcType &&
                                        governance.attributes[
                                          "calculation-type"
                                        ] == "percent_of_total")
                                        ? (
                                            parseFloat(
                                              governance.attributes.rate
                                            ) * 100
                                          ).toString() + "%"
                                        : governance.attributes["fixed-hours"]}
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Col>

                            <div
                              className="flexCenter"
                              style={
                                governance.userIsEditing
                                  ? { marginTop: "-85px" }
                                  : { marginTop: "-20px" }
                              }
                            >
                              {!governance.userIsEditing &&
                              governancesToDisplay.filter(
                                (gov) => gov.userIsEditing
                              ).length > 0 ? null : (
                                <FontAwesomeIcon
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  icon={
                                    governance.userIsEditing
                                      ? faCheck
                                      : faPencil
                                  }
                                  onClick={() => {
                                    if (
                                      !governance.userIsEditing &&
                                      governancesToDisplay.filter(
                                        (gov) => gov.userIsEditing
                                      ).length == 0
                                    ) {
                                      governance.userIsEditing = true;
                                      setGovernancesToDisplay(
                                        governancesToDisplay.map((gov) => {
                                          if (
                                            gov.id == governance.id &&
                                            !governance.userIsEditing
                                          ) {
                                            gov.userIsEditing = true;
                                          }
                                          return gov;
                                        })
                                      );
                                    }
                                    if (
                                      governance.userIsEditing &&
                                      (governance.governanceNameChanged ||
                                        governance.detailsChanged ||
                                        governance.newResourceId)
                                    ) {
                                      submitGovernance(governance);
                                    } else if (
                                      governance.userIsEditing &&
                                      governance.attributes.name ==
                                        "Enter governance name"
                                    ) {
                                      deleteGovernance(governance);
                                    }
                                  }}
                                />
                              )}
                              {!governance.userIsEditing &&
                              governancesToDisplay.filter(
                                (gov) => gov.userIsEditing
                              ).length > 0 ? null : (
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  icon={
                                    governance.userIsEditing ? faX : faTrashCan
                                  }
                                  onClick={() => {
                                    if (
                                      governance.id.includes("newGovernance")
                                    ) {
                                      deleteGovernance(governance);
                                    }
                                    if (
                                      governance.userIsEditing &&
                                      !governance.id.includes("newGovernance")
                                    ) {
                                      setGovernancesToDisplay(
                                        governancesToDisplay.map((gov) => {
                                          if (gov.id == governance.id) {
                                            gov.userIsEditing = false;
                                            gov.governanceNameChanged = false;
                                            gov.detailsChanged = false;
                                            delete gov.newGovernanceName;
                                            delete gov.newCalcType;
                                            delete gov.newResourceId;
                                            delete gov.newHours;
                                            delete gov.newRate;
                                          }
                                          return gov;
                                        })
                                      );
                                    } else if (
                                      !governance.userIsEditing &&
                                      !governance.id.includes("newGovernance")
                                    ) {
                                      setGovernanceToDelete(governance);
                                      setShowGovernanceConfirmation(true);
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      );
    }
  };
  return (
    <>
      <ConfirmationModal
        show={showGovernanceConfirmation}
        title="Delete Governance"
        message="Are you sure you want to delete this governance?"
        onConfirm={() => deleteGovernance(governanceToDelete)}
        onCancel={() => {
          setGovernanceToDelete({});
          setShowGovernanceConfirmation(false);
        }}
      />
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <OnboardingCards
        contentCardHeader={"Let’s tailor your account to the way you work"}
        leftContent={
          <>
            {governanceText}
            {getGovernances()}
          </>
        }
        rightContent={
          <div>
            <img
              src={logisticsMonitorWifi}
              alt="caricature of person icon on a monitor screen, yellow wifi symbol, yellow servers and green money icon"
              style={{ width: "100%" }}
            />
          </div>
        }
        rightButtonText={"Continue"}
        rightButtonOnClick={() => {
          setRerender(true);
          navigate("/onboarding/standard-services");
        }}
        disabledRightButton={
          governancesToDisplay.filter((gov) => !matchGovernanceToResource(gov))
            .length > 0 ||
          governancesToDisplay.filter((resRate) => resRate.userIsEditing)
            .length !== 0
        }
        skipSetupButton={true}
      />
    </>
  );
}

export default Governances;
