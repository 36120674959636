import React, { useState } from "react";
import CollaboratorsV2Table from "./CollaboratorsV2Table";
import useListCollaborators from "./api/useListCollaborators";
import { useListUsersForAccountQuery } from "@generated";
import useListProjectVariables from "../ProjectVariables/api/useListProjectVariables";
import useWhoAmI from "../Account/EditAccount/api/useWhoAmI";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function CollaboratorsV2({ project_id, account_slug }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const { teammates, project, error, isLoading, pageMeta, refetch } =
    useListCollaborators({ pageSize, pageNumber, projectId: project_id });

  // Pagination/API
  const listUsersApiArg = {
    slug: account_slug,
    page: {
      number: 1,
      size: 1000,
    },
  };
  const {
    data: users,
    error: listUsersError,
    isLoading: usersLoading,
    refetch: usersRefetch,
  } = useListUsersForAccountQuery({
    ...listUsersApiArg,
    filter: { "view-only": false },
  });

  const {
    data: currentUser,
    error: currentUserError,
    isLoading: currentUserLoading,
  } = useWhoAmI();

  const {
    allProjectVariables,
    isLoading: projectVariablesLoading,
    error: projVariablesError,
    refetch: projVariablesRefetch,
    isFetching,
  } = useListProjectVariables("teammate");

  if (
    isLoading ||
    usersLoading ||
    currentUserLoading ||
    projectVariablesLoading
  ) {
    return <ScopeStackSpinner />;
  }
  return (
    <CollaboratorsV2Table
      project_id={project_id}
      account_slug={account_slug}
      teammatesPassed={teammates}
      projectPassed={project}
      refetch={refetch}
      usersPassed={users}
      currentUserPassed={currentUser}
      allProjectVariablesPassed={allProjectVariables}
    />
  );
}

export default CollaboratorsV2;
