import {
  V1ProjectResourceResource,
  useListProjectResourcesForAccountQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";

const useListProjectResources = (projectId) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  const { data, error, isLoading, refetch } =
    useListProjectResourcesForAccountQuery({
      slug: accountSlug,
      page: { size: 1000 },
      filter: { project: projectId, active: true },
    });

  let projectResources: V1ProjectResourceResource[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      projectResources = data?.data;

      if (!data?.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data?.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data?.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data?.meta["record-count"],
        pageCount: data?.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    projectResources,
    projectResourcesError: error || customError,
    projectResourcesLoading: isLoading,
    pageMeta,
    ProjectResourcesRefetch: refetch,
  };
};

export default useListProjectResources;
