import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import React, { ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import { JsxElement } from "typescript";

interface FormNumberFieldV2Props {
    label: string | null;
    placeholder?: string;
    value?: string | number | null;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    defaultValue?: string;
    required?: boolean;
    updateRef?: boolean;
    id?: string;
    min?: number;
    max?: number;
    readOnly?: boolean;
    step?: number;
    fieldFooter? : string | undefined;
    onBlur?: (e?:any) => void;
  }

const FormNumberFieldV2: React.FC<FormNumberFieldV2Props> = ({
  label,
  placeholder,
  value,
  onChange,
  min,
  max,
  required,
  defaultValue,
  readOnly,
  step,
  fieldFooter,
  onBlur
}) => {
  return (
    <Form.Group className="formGroup">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        readOnly={readOnly}
        required={required}
        defaultValue={defaultValue}
        value={!value || value === null ? "" : value}
        placeholder={placeholder}
        onChange={onChange}
        type="number"
        min={min}
        max={max}
        step={step}
        onBlur={onBlur ? onBlur : () => null}
      />
      {fieldFooter ? <small>{fieldFooter}</small> : null}
    </Form.Group>
  );
}

export default FormNumberFieldV2;
