import React, { useState, useEffect } from "react";
import OnboardingCards from "../reusable/OnboardingCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form } from "react-bootstrap";
import fileCheckYellow from "../images/fileCheckYellow.png";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  faCheck,
  faX,
  faPencil,
  faTrashCan,
  faGripDotsVertical,
} from "@fortawesome/pro-solid-svg-icons";
import API from "@utils/API/API";
import {
  formatUnprocessibleResponse,
  handleAlert,
  validateDrop,
} from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { useNavigate } from "react-router";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function PhaseComponent({
  phases,
  allPhases,
  isLoading,
  account_slug,
  account_id,
  authorizationCode,
  setRerender,
}) {
  const [phasesToDisplay, setPhasesToDisplay] = useState([]);
  const [errorMessages, setErrorMessages] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showPhaseConfirmation, setShowPhaseConfirmation] = useState(false);
  const [phaseToDelete, setPhaseToDelete] = useState({});
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  let navigate = useNavigate();

  useEffect(() => {
    let newPhases = [...phases].map((phase) => {
      phase.userIsEditing = false;
      phase.phaseNameChanged = false;
      return phase;
    });
    setPhasesToDisplay(
      newPhases.sort((a, b) => a.attributes.position - b.attributes.position)
    );
  }, [phases, isLoading]);

  useEffect(() => {
    if (!authorizationCode) return;

    API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
      API.Patch(
        `${apiHost}/${account_slug}/v1/users/${response.data.data.id}`,
        {
          data: {
            id: response.data.data.id,
            type: "users",
            attributes: {
              "guided-onboarding": {
                onboarding_status: "phases",
              },
            },
          },
        },
        authorizationCode
      ).then((response) => {
        // console.log(response);
      });
    });
  }, [authorizationCode]);

  window.onpopstate = function (e) {
    setRerender(true);
  };

  const addPhase = () => {
    let newPhase = {
      id: `newPhase-${Math.floor(Math.random() * 100)}`,
      userIsEditing: true,
      phaseNameChanged: false,
      attributes: { name: "" },
    };
    if (phasesToDisplay.filter((p) => p.userIsEditing).length == 0) {
      let newPhases = [...phasesToDisplay];
      newPhases.splice(0, 0, newPhase);
      setPhasesToDisplay(newPhases);
    }
  };

  const deletePhase = (phaseToDelete) => {
    setShowPhaseConfirmation(false);
    if (phaseToDelete) {
      if (phaseToDelete.id.includes("newPhase")) {
        setPhasesToDisplay(
          phasesToDisplay.filter((phase) => phase.id !== phaseToDelete.id)
        );
        return;
      } else if (phasesToDisplay.length > 1) {
        let phaseData = {
          data: {
            type: "phases",
            id: phaseToDelete.id,
          },
        };
        API.Delete(
          `${apiHost}/${account_slug}/v1/phases/${phaseToDelete.id}`,
          phaseData,
          authorizationCode
        )
          .then((res) => {
            setPhasesToDisplay(
              phasesToDisplay.filter((phase) => phase.id !== phaseToDelete.id)
            );
          })
          .catch((err) => {
            setErrorMessages(formatUnprocessibleResponse(err, "phase"));
            setShowFailAlert(true);
          });
      } else if (phasesToDisplay.length == 1) {
        setErrorMessages(
          "You must have at least one phase to be able to create services."
        );
        setShowFailAlert(true);
      }
    }
  };

  const submitPhase = (phase) => {
    let submitMethod = "POST";
    let url = `${apiHost}/${account_slug}/v1/phases`;
    let phaseData = {
      data: {
        type: "phases",
        attributes: {
          name: phase.newPhaseName ? phase.newPhaseName : phase.attributes.name,
        },
      },
    };
    if (!phase.id.includes("newPhase")) {
      phaseData.data.id = phase.id;
      submitMethod = "PATCH";
      url = `${apiHost}/${account_slug}/v1/phases/${phase.id}`;
    }
    API.Submit(submitMethod, url, phaseData, authorizationCode)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setPhasesToDisplay(
            phasesToDisplay.map((p) => {
              if (p.id.includes("newPhase") && submitMethod == "POST") {
                p.id = res.data.data.id;
              }
              if (p.id == res.data.data.id) {
                p.userIsEditing = false;
                p.phaseNameChanged = false;
                p.attributes.name = res.data.data.attributes.name;
              }
              return p;
            })
          );
        }
      })
      .catch((err) => {
        let error = formatUnprocessibleResponse(err, "phase");
        if (
          error.includes("Could not save phase. Name has already been taken") &&
          phasesToDisplay.filter(
            (p) =>
              (p.attributes.name === phase.attributes.name ||
                (phase.newPhaseName &&
                  phase.newPhaseName == p.attributes.name)) &&
              p.id !== phase.id
          ).length == 0
        ) {
          let phaseToReactivate = {};
          allPhases.forEach((p) => {
            if (
              (p.attributes.name === phase.attributes.name ||
                (phase.newPhaseName &&
                  phase.newPhaseName == p.attributes.name)) &&
              p.id !== phase.id
            ) {
              phaseToReactivate = p;
            }
          });
          API.Patch(
            phaseToReactivate.links.self,
            {
              data: {
                id: phaseToReactivate.id,
                type: "phases",
                attributes: {
                  active: true,
                  position: phasesToDisplay.length + 1,
                },
              },
            },
            authorizationCode
          ).then((res) => {
            let reactivatedPhase = res.data.data;
            reactivatedPhase.userIsEditing = false;
            reactivatedPhase.phaseNameChanged = false;
            deletePhase(phase);
            if (submitMethod == "PATCH") {
              phasesToDisplay.push(reactivatedPhase);
            } else {
              setPhasesToDisplay([
                ...phasesToDisplay.filter((p) => p.id != phase.id),
                reactivatedPhase,
              ]);
            }
          });
        } else {
          setErrorMessages(error);
          setShowFailAlert(true);
        }
      });
  };

  const dragPhaseEnd = (result) => {
    let isValid = validateDrop(result);
    if (!isValid) return;
    const { source, destination } = result;
    const copyListItems = [...phasesToDisplay];
    const dragItemContent = copyListItems[source.index];
    if (destination.droppableId !== "PhaseDropZone") return;
    copyListItems.splice(source.index, 1);
    copyListItems.splice(destination.index, 0, dragItemContent);
    let newPhases = phasesToDisplay.filter(
      (o1) => !copyListItems.some((o2) => o1.id === o2.id)
    );
    setPhasesToDisplay(newPhases.concat(copyListItems));
    let phaseToUpdate = dragItemContent;
    phaseToUpdate.type = "phases";
    delete phaseToUpdate.userIsEditing;
    delete phaseToUpdate.phaseNameChanged;
    delete phaseToUpdate.newPhaseName;
    delete phaseToUpdate.links;
    phaseToUpdate.attributes = {
      name: dragItemContent.attributes.name,
      position:
        destination.index == 0 ? 1 : copyListItems.indexOf(dragItemContent) + 1,
    };
    phaseToUpdate.relationships = {
      account: { data: { id: account_id, type: "accounts" } },
    };
    API.Patch(
      `${apiHost}/${account_slug}/v1/phases/${phaseToUpdate.id}/move-to`,
      { data: phaseToUpdate },
      authorizationCode
    ).catch((err) => {
      setErrorMessages(formatUnprocessibleResponse(err, "phase"));
      setShowFailAlert(true);
    });
  };

  return (
    <>
      <ConfirmationModal
        show={showPhaseConfirmation}
        title="Delete Phase"
        message="Are you sure you want to delete this phase?"
        onConfirm={() => deletePhase(phaseToDelete)}
        onCancel={() => {
          setPhaseToDelete({});
          setShowPhaseConfirmation(false);
        }}
      />
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <OnboardingCards
        contentCardHeader={"Let’s tailor your account to the way you work"}
        leftContent={
          <div>
            <p>
              Here are examples of the <strong>Phases</strong> (milestones) that
              we use by default.
            </p>
            <p>Customize, add, or remove these to match your process.</p>
            {isLoading ? (
              <SmallSpinner />
            ) : (
              <>
                <Row
                  style={{
                    marginLeft: "1px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className={"cursorPoint whiteBar"}
                  onClick={() => addPhase()}
                >
                  <Col style={{ paddingLeft: "5px" }}>
                    <strong
                      style={
                        phasesToDisplay.filter((p) => p.userIsEditing).length ==
                        0
                          ? { color: "#418172" }
                          : { color: "darkgray" }
                      }
                    >
                      Add a Phase +
                    </strong>
                  </Col>
                </Row>

                <DragDropContext onDragEnd={dragPhaseEnd}>
                  <Droppable droppableId="PhaseDropZone">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {provided.placeholder}
                        {phasesToDisplay.map((phase, index) => (
                          <Draggable
                            key={phase.id}
                            draggableId={phase.id}
                            index={index}
                            isDragDisabled={false}
                          >
                            {(provided) => (
                              <div
                                key={phase.id}
                                id={phase.id}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {provided.placeholder}
                                <Row
                                  style={
                                    phase.userIsEditing
                                      ? {
                                          padding: "4px 10px 4px 4px",
                                          height: "45px",
                                          marginLeft: "1px",
                                          display: "flex",
                                          alignItems: "center",
                                        }
                                      : {
                                          marginLeft: "1px",
                                          display: "flex",
                                          alignItems: "center",
                                        }
                                  }
                                  className={"whiteBar"}
                                >
                                  <Col style={{ paddingLeft: "5px" }}>
                                    {phase.userIsEditing ? (
                                      <Form.Control
                                        type="text"
                                        value={
                                          phase.phaseNameChanged
                                            ? phase.newPhaseName
                                            : phase.attributes.name
                                        }
                                        placeholder={"Enter phase name"}
                                        onChange={(e) => {
                                          phase.newPhaseName = e.target.value;
                                          phase.phaseNameChanged = true;
                                          setPhasesToDisplay(
                                            phasesToDisplay.map((p) => {
                                              if (p.id == phase.id) {
                                                p.newPhaseName = e.target.value;
                                                p.phaseNameChanged = true;
                                              }
                                              return p;
                                            })
                                          );
                                        }}
                                      />
                                    ) : (
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          className="gripIcon"
                                          icon={faGripDotsVertical}
                                        />

                                        <strong>{phase.attributes.name}</strong>
                                      </span>
                                    )}
                                  </Col>

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {!phase.userIsEditing &&
                                    phasesToDisplay.filter(
                                      (p) => p.userIsEditing
                                    ).length > 0 ? null : (
                                      <FontAwesomeIcon
                                        style={{
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                        icon={
                                          phase.userIsEditing
                                            ? faCheck
                                            : faPencil
                                        }
                                        onClick={() => {
                                          if (
                                            !phase.userIsEditing &&
                                            phasesToDisplay.filter(
                                              (p) => p.userIsEditing
                                            ).length == 0
                                          ) {
                                            phase.userIsEditing = true;
                                            setPhasesToDisplay(
                                              phasesToDisplay.map((p) => {
                                                if (
                                                  p.id == phase.id &&
                                                  !phase.userIsEditing
                                                ) {
                                                  p.userIsEditing = true;
                                                }
                                                return p;
                                              })
                                            );
                                          }
                                          if (
                                            phase.userIsEditing &&
                                            phase.phaseNameChanged
                                          ) {
                                            submitPhase(phase);
                                          } else if (
                                            phase.userIsEditing &&
                                            phase.attributes.name ==
                                              "Enter phase name"
                                          ) {
                                            deletePhase(phase);
                                          }
                                        }}
                                      />
                                    )}
                                    {!phase.userIsEditing &&
                                    phasesToDisplay.filter(
                                      (p) => p.userIsEditing
                                    ).length > 0 ? null : (
                                      <FontAwesomeIcon
                                        style={{ cursor: "pointer" }}
                                        icon={
                                          phase.userIsEditing ? faX : faTrashCan
                                        }
                                        onClick={() => {
                                          if (phase.id.includes("newPhase")) {
                                            deletePhase(phase);
                                          }
                                          if (
                                            phase.userIsEditing &&
                                            !phase.id.includes("newPhase")
                                          ) {
                                            setPhasesToDisplay(
                                              phasesToDisplay.map((p) => {
                                                if (p.id == phase.id) {
                                                  p.userIsEditing = false;
                                                  p.phaseNameChanged = false;
                                                  delete p.newPhaseName;
                                                }
                                                return p;
                                              })
                                            );
                                          } else if (
                                            !phase.userIsEditing &&
                                            !phase.id.includes("newPhase")
                                          ) {
                                            setPhaseToDelete(phase);
                                            setShowPhaseConfirmation(true);
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                </Row>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}
          </div>
        }
        rightContent={
          <div>
            <img
              src={fileCheckYellow}
              alt="caricature files with yellow check mark, clock, and green hexagon"
              style={{ width: "100%" }}
            />
          </div>
        }
        rightButtonText={"Continue"}
        rightButtonOnClick={() => {
          setRerender(true);
          navigate("/onboarding/language-fields-info");
        }}
        disabledRightButton={
          phasesToDisplay.filter((phase) => phase.userIsEditing).length !== 0
        }
        skipSetupButton={true}
      />
    </>
  );
}

export default PhaseComponent;
