import React, { useEffect, useState } from "react";
import FolderTreeComponent from "./FolderTreeComponent";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import { Button, Col, Row } from "react-bootstrap";
import { FileTreeData } from "./types/fileTreeData";
import API from "../../utils/API/API";
import { getPathname, snakeToText } from "@utils/helperFunctions";

interface FolderTreeProps {
  showFileTreeModal: boolean;
  setShowFileTreeModal: (boolState) => void;
  account_slug: string;
  authorizationCode: string;
  storageOptions: any;
  fileTreeDataPassed?: FileTreeData;
  indentPixels?: number;
  showCheckbox?: boolean;
  readOnly?: boolean;
  onNameClickPassed?: ({
    defaultOnClick,
    nodeData,
  }: {
    defaultOnClick: any;
    nodeData: any;
  }) => void;
  onChange?: (e: any) => void;
  DeleteIconPassed?: any;
  AddFileIconPassed?: any;
  FileIconPassed?: any;
  CaretRightIconPassed?: any;
  FolderIconPassed?: any;
  EditIconPassed?: any;
  CaretDownIconPassed?: any;
  AddFolderIconPassed?: any;
  OpenFolderIconPassed?: any;
  selectedConnection?: any;
  selectedIntegration?: any;
  canCreateFolder?: boolean;
  canEditFolderName?: boolean;
  setSelectedIntegration?: (state) => void;
  setSelectedConnection?: (state) => void;
  folderLoading?: boolean;
  setFolderLoading?: (boolState) => void;
  selectedFolder?: null | { id: string; name: string };
  setSelectedFolder?: (state) => void;
  onUseFolder?: () => void;
  isChooseRootFolder: boolean;
}

function FolderTreeComponentModal({
  showCheckbox,
  readOnly,
  fileTreeDataPassed,
  onNameClickPassed,
  onChange,
  DeleteIconPassed,
  AddFileIconPassed,
  FileIconPassed,
  OpenFolderIconPassed,
  CaretRightIconPassed,
  AddFolderIconPassed,
  EditIconPassed,
  FolderIconPassed,
  CaretDownIconPassed,
  indentPixels,
  account_slug,
  authorizationCode,
  selectedConnection,
  selectedIntegration,
  showFileTreeModal,
  setShowFileTreeModal,
  setSelectedIntegration,
  setSelectedConnection,
  storageOptions,
  folderLoading,
  setFolderLoading,
  selectedFolder,
  setSelectedFolder,
  canCreateFolder,
  canEditFolderName,
  onUseFolder,
  isChooseRootFolder,
}: FolderTreeProps) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [rootFolder, setRootFolder] = useState("");
  const [allFolders, setAllFolders] = useState<any[] | []>([]);
  const [allFiles, setAllFiles] = useState<any[] | []>([]);
  const [originalSelectedFolder, setOriginalSelectedFolder] = useState<null | {
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });
  const [fileTreeData, setFileTreeData] = useState<FileTreeData>(
    fileTreeDataPassed
      ? fileTreeDataPassed
      : {
          name: "",
          children: [],
        }
  );

  const setTreeData = (folders, files) => {
    setAllFiles(files);
    let foldersWithChildren = folders.map((folder) => {
      folder = {
        ...folder,
        children: (
          folders.filter((f) => f.parent_folder_id == folder.id) || []
        ).concat(files.filter((file) => file.folder_id == folder.id) || []),
      };
      return folder;
    });
    const rootFromConnection = folders.find(
      (f) =>
        f.id == selectedConnection?.attributes?.settings?.root_folder_id ||
        f.id == selectedConnection?.settings?.root_folder_id
    );
    let foldersInRoot =
      !isChooseRootFolder && rootFromConnection
        ? foldersWithChildren.filter(
            (folder) => folder.parent_folder_id == rootFromConnection.id
          )
        : foldersWithChildren.filter(
            (folder) => folder.parent_folder_id === null
          );
    foldersInRoot = foldersInRoot.map((folder) => {
      folder = {
        ...folder,
        children: Array.from(
          new Set(
            (folders.filter((f) => f.parent_folder_id == folder.id) || [])
              .concat(files.filter((file) => file.folder_id == folder.id) || [])
              .map((child) => {
                if (child.type == "folder") {
                  child = {
                    ...child,
                    children: Array.from(
                      new Set(
                        (
                          folders.filter(
                            (f) => f.parent_folder_id == child.id
                          ) || []
                        ).concat(
                          files.filter((file) => file.folder_id == child.id) ||
                            []
                        )
                      )
                    ),
                  };
                }
                return child;
              })
          )
        ),
      };
      return folder;
    });
    if (!isChooseRootFolder) {
      foldersInRoot = foldersInRoot.filter(
        (f) => f.parent_folder_id == rootFromConnection?.id
      );
    }
    const fileTreeData = isChooseRootFolder
      ? foldersInRoot.concat(files.filter((file) => file.folder_id === null))
      : foldersInRoot.concat(
          files.filter((file) => file.folder_id == rootFromConnection?.id)
        );
    const rootFolder =
      !isChooseRootFolder && rootFromConnection
        ? rootFromConnection
        : getPathname(foldersInRoot[0].file_url);
    const rootFolderName =
      rootFromConnection && !isChooseRootFolder
        ? rootFromConnection?.name
        : rootFolder.split("/")[1];
    setRootFolder(rootFolderName);
    const folderId = storageOptions?.[selectedConnection.id]?.storage_id;
    let folder = {
      id: rootFromConnection ? rootFromConnection.id : "0",
      name: rootFromConnection ? rootFromConnection.name : rootFolderName,
      file_url: `/${
        rootFromConnection ? rootFromConnection.name : rootFolderName
      }`,
      type: "folder",
    };
    setAllFolders([folder, ...folders]);
    let foundFolder;
    if (folderId) {
      foundFolder = folders.find((folder) => folder.id == folderId);
      if (foundFolder) {
        folder = {
          id: foundFolder.id,
          name: getPathname(foundFolder.file_url),
          file_url: foundFolder.file_url,
          type: "folder",
        };
      }
    }
    if (setSelectedFolder) {
      setSelectedFolder(folder);
    }
    setOriginalSelectedFolder(folder);
    if (setFolderLoading) {
      setFolderLoading(false);
    }
    setFileTreeData({
      name: `${snakeToText(rootFolderName)}`,
      children: fileTreeData,
    });
  };

  const getFileTreeData = (accountConnection) => {
    if (accountConnection && authorizationCode !== "") {
      if (setSelectedConnection) {
        setSelectedConnection(accountConnection);
      }
      API.Get(
        `${apiHost}/${account_slug}/v1/file-storages/${accountConnection.id}/get-contents`,
        authorizationCode
      ).then((res) => {
        const folders = res.data.data.attributes.folders.map((folder) => {
          folder = { ...folder, type: "folder", children: [] };
          return folder;
        });
        const files = res.data.data.attributes.files.map((file) => {
          file = { ...file, type: "file" };
          return file;
        });
        setTreeData(folders, files);
      });
    } else {
      if (setFolderLoading) {
        setFolderLoading(false);
      }
    }
  };

  useEffect(() => {
    getFileTreeData(selectedConnection);
  }, [selectedConnection]);

  return (
    <ScopeStackModal
      className="fileTreeModal"
      show={showFileTreeModal}
      modalTitle={
        selectedConnection ? (
          <>
            <Row>
              <Col>
                <img
                  style={{ width: "110px" }}
                  src={selectedIntegration?.["provider"]?.["logo"]}
                />
              </Col>
              <Col
                className="flexCenter"
                style={{ marginLeft: "344px", marginTop: "10px" }}
              >
                <Button
                  onClick={() => {
                    if (setSelectedFolder)
                      setSelectedFolder(originalSelectedFolder);
                    setShowFileTreeModal(false);
                  }}
                  className="ssButtonBgWhite"
                  style={{ width: "110px", marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setOriginalSelectedFolder(
                      selectedFolder || { id: "0", name: rootFolder }
                    );
                    setShowFileTreeModal(false);
                    if (onUseFolder) {
                      onUseFolder();
                    }
                  }}
                  className="squareGreenButton"
                  style={{ width: "112px" }}
                >
                  Use Folder
                </Button>
              </Col>
            </Row>
            <Row className="marginTop10">
              <Col>
                <div style={{ fontSize: "14px" }}>
                  <span>Selected Folder:</span>&nbsp;
                  <span style={{ fontWeight: "normal" }}>
                    {selectedFolder?.name}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          "You must click an icon to select a connection before you can choose a folder"
        )
      }
      modalBody={
        selectedConnection ? (
          <FolderTreeComponent
            fileTreeData={fileTreeData}
            setFileTreeData={setFileTreeData}
            storageOptions={storageOptions}
            rootFolder={rootFolder}
            setRootFolder={setRootFolder}
            allFolders={allFolders}
            setAllFolders={setAllFolders}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
            setTreeData={setTreeData}
            showCheckbox={showCheckbox}
            readOnly={readOnly}
            indentPixels={indentPixels}
            account_slug={account_slug}
            authorizationCode={authorizationCode}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            setOriginalSelectedFolder={setOriginalSelectedFolder}
            folderLoading={folderLoading}
            setFolderLoading={setFolderLoading}
            selectedConnection={selectedConnection}
            setSelectedConnection={setSelectedConnection}
            selectedIntegration={selectedIntegration}
            setSelectedIntegration={setSelectedIntegration}
            canCreateFolder={canCreateFolder}
            canEditFolderName={canEditFolderName}
            DeleteIconPassed={DeleteIconPassed}
            AddFileIconPassed={AddFileIconPassed}
            FileIconPassed={FileIconPassed}
            CaretRightIconPassed={CaretRightIconPassed}
            FolderIconPassed={FolderIconPassed}
            EditIconPassed={EditIconPassed}
            CaretDownIconPassed={CaretDownIconPassed}
            AddFolderIconPassed={AddFolderIconPassed}
            OpenFolderIconPassed={OpenFolderIconPassed}
            onNameClickPassed={onNameClickPassed}
            showFileTreeModal={showFileTreeModal}
            setShowFileTreeModal={setShowFileTreeModal}
          />
        ) : null
      }
      button1Text={null}
      button2Text={null}
      handleClose={() => {
        if (setShowFileTreeModal) setShowFileTreeModal(false);
      }}
      handleButton1Click={() => null}
      handleButton2Click={() => null}
    />
  );
}

export default FolderTreeComponentModal;
