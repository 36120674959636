import { useListProjectsForAccountQuery } from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { Project } from "../types";
import { BaseRelationship } from "@baseTypes";
import useGetUserViewResource from "./useGetUserViewResource";
import { useState } from "react";

const useListRecentProjects = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [isRefetchingProjects, setIsRefetchingProjects] = useState(false);
  const [isRefetchingUserView, setIsRefetchingUserView] = useState(false);

  const {
    userViews,
    isLoading: userViewLoading,
    error: userViewError,
    refetch: userViewRefetchOriginal,
  } = useGetUserViewResource();

  const userViewRefetch = async () => {
    setIsRefetchingUserView(true);
    await userViewRefetchOriginal();
    setIsRefetchingUserView(false);
  };

  const filterValidStatuses = (
    statuses: string[]
  ): Array<
    | "building"
    | "technical_approval"
    | "sales_approval"
    | "business_approval"
    | "approved"
    | "won"
    | "lost"
    | "canceled"
  > => {
    const validStatuses = [
      "building",
      "technical_approval",
      "sales_approval",
      "business_approval",
      "approved",
      "won",
      "lost",
      "canceled",
    ];
    return statuses.filter((status) => validStatuses.includes(status)) as any;
  };

  const baseFilters = {};

  const filters = {
    ...baseFilters,
    ...(userViews[0]?.filters?.clients.length > 0 && {
      "client.id": userViews[0]?.filters?.clients.join(", "),
    }),
    ...(userViews[0]?.filters?.salesExecs.length > 0 && {
      "sales-executive.id": userViews[0]?.filters?.salesExecs.join(", "),
    }),
    ...(userViews[0]?.filters?.presalesEngineers.length > 0 && {
      "presales-engineer.id":
        userViews[0]?.filters?.presalesEngineers.join(", "),
    }),
    ...(userViews[0]?.filters?.teammates.length > 0 && {
      "creator.id": userViews[0]?.filters?.teammates.join(", "),
    }),
    ...(userViews[0]?.filters?.tags.length > 0 && {
      "tag-list": userViews[0]?.filters?.tags.join(", "),
    }),
    ...(userViews[0]?.filters?.status.length > 0 && {
      status: filterValidStatuses(userViews[0]?.filters?.status) as any, // type mismatch in API
    }),
    ...(userViews[0]?.filters?.dateCreatedStart.length > 0 && {
      "created-at.after": userViews[0]?.filters?.dateCreatedStart,
    }),
    ...(userViews[0]?.filters?.dateCreatedEnd.length > 0 && {
      "created-at.before": userViews[0]?.filters?.dateCreatedEnd,
    }),
    ...(userViews[0]?.filters?.dateEditedStart.length > 0 && {
      "updated-at.after": userViews[0]?.filters?.dateEditedStart,
    }),
    ...(userViews[0]?.filters?.dateEditedEnd.length > 0 && {
      "updated-at.before": userViews[0]?.filters?.dateEditedEnd,
    }),
  };

  const isFiltered = Object.values(userViews[0]?.filters || {}).some(
    (filterArray) => Array.isArray(filterArray) && filterArray.length > 0
  );

  const {
    data,
    isLoading: projectLoading,
    error,
    refetch: refetchProjectsOriginal,
  } = useListProjectsForAccountQuery({
    slug: accountSlug,
    page: { size: 9, number: 1 },
    filter: filters,
    include: ["client"],
    sort: ["-created-at"],
  });

  const refetchProjects = async () => {
    setIsRefetchingProjects(true);
    await refetchProjectsOriginal();
    setIsRefetchingProjects(false);
  };

  let projects: Project[] = [];
  let customError: string | null = null;

  if (data?.data) {
    try {
      projects = data.data
        .filter((project) => !!project.id)
        .map((project) => {
          const client = project.relationships.client
            ?.data as unknown as BaseRelationship;
          let clientName = "";

          if (data.included) {
            const includedClients = data.included.filter(
              (includedItem) => includedItem.type === "clients"
            );
            const foundClient = includedClients.find(
              (item) => String(client.id) === String(item.id)
            );
            clientName =
              foundClient &&
              foundClient.attributes &&
              foundClient.attributes["name"]
                ? foundClient.attributes["name"]
                : "";
          }

          return {
            id: project.id?.toString() ?? "",
            name: project.attributes["project-name"],
            clientName,
          };
        });
    } catch (e) {
      if (e instanceof Error) {
        customError = e.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  const isLoading =
    projectLoading ||
    isRefetchingProjects ||
    userViewLoading ||
    isRefetchingUserView;
  const refetch = () => {
    refetchProjects();
    userViewRefetch();
  };

  return {
    data: projects,
    isLoading,
    error: error ?? customError,
    refetch,
    userViewRefetch,
    isFiltered: isFiltered,
  };
};

export default useListRecentProjects;
