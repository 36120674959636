import React, { useEffect, useState } from "react";
import DropDownField from "@components/Forms/DropDownField";
import { formatDate } from "@utils/helperFunctions";
import { Col, Row } from "react-bootstrap";
import { ProjectVariable } from "../Admin/ProfessionalServices/common/types/ProjectVariable";
import MultipleLineFieldV2 from "@components/FormsV2/MultipleLineFieldV2";
import { FormNumberFieldV2 } from "@components/FormsV2/FormNumberFieldV2";
import { DateField, TextField } from "@components/FormsV2";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import useOauth from "../../utils//customHooks/useOauth";
import API from "../../utils/API/API";

interface ProjectVariableProps {
  pvArrPassed?: ProjectVariable[];
  context?: string;
  overridePadding?: boolean;
  setState?: (state) => void | null;
  setVariableChanged?: (boolean) => void | null;
  requiredOnly?: boolean;
  unrequiredOnly?: boolean;
  accountSlug?: string;
  hasGreyBox?: boolean;
  hasSectionTitle?: boolean;
  hasTopBorder?: boolean;
  has3PerRow?: boolean;
  isInDataTable?: boolean;
  onBlur?: (e) => void;
}

function ProjectVariablesV2({
  pvArrPassed,
  context,
  overridePadding = false,
  setState,
  setVariableChanged,
  requiredOnly,
  unrequiredOnly,
  accountSlug,
  hasGreyBox,
  hasSectionTitle,
  hasTopBorder,
  has3PerRow,
  isInDataTable,
  onBlur,
}: ProjectVariableProps) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [authorizationCode] = pvArrPassed ? "" : useOauth();
  let [arr, setArr] = useState<ProjectVariable[]>([]);
  const components: any[] = [];
  const [isLoading, setIsLoading] = useState(true);
  const [allVariables, setAllVariables] = useState(
    pvArrPassed ? pvArrPassed : []
  );

  useEffect(() => {
    if (pvArrPassed) {
      if (requiredOnly) {
        setArr(pvArrPassed.filter((pv) => pv.required));
      } else if (unrequiredOnly) {
        setArr(pvArrPassed.filter((pv) => !pv.required));
      } else {
        setArr(pvArrPassed);
      }
      setIsLoading(false);
    } else {
      if (authorizationCode !== "") {
        API.Get(
          `${apiHost}/${accountSlug}/v1/project-variables?filter[variable-context]=${context}`,
          authorizationCode
        ).then((response) => {
          const data = response.data.data;
          let variables = data.map((pv) => {
            return {
              id: pv.id,
              ...pv.attributes,
              value:
                (pv?.attributes?.["variable-type"] === "date" ||
                  pv?.attributes?.["variable_type"] === "date") &&
                pv?.attributes?.required
                  ? formatDate(new Date())
                  : "",
            };
          });
          if (data.length !== 0) {
            if (requiredOnly) {
              setArr(variables.filter((pv) => pv.required));
            } else if (unrequiredOnly) {
              setArr(variables.filter((pv) => !pv.required));
            } else {
              setArr(variables);
              setAllVariables(variables);
            }
          }
          setIsLoading(false);
        });
      }
    }
  }, [authorizationCode]);

  useEffect(() => {
    if (setState) {
      setState(arr);
    }
  }, [arr]);

  arr.map((pv, index) => {
    let selectOptions = pv["select_options"] || [];
    let variableType = "";
    if (pv["variable-type"]) {
      variableType = pv["variable-type"];
    }
    if (pv["variable_type"]) {
      variableType = pv["variable_type"];
    }

    const renderKey = () => {
      for (let i = 0; i < selectOptions.length; i++) {
        if (pv.value === selectOptions[i].value) {
          return selectOptions[i].key;
        }
        if (selectOptions[i]?.["default"] && (!pv.value || pv.value == "")) {
          return selectOptions[i].key;
        }
      }
    };
    if (
      selectOptions.length !== 0 ||
      (pv["select-options"] && pv["select-options"].length !== 0)
    ) {
      if (selectOptions.length === 0) {
        selectOptions = pv["select-options"];
      }
      components.push(
        <Col
          sm={isInDataTable ? 12 : has3PerRow ? 4 : 6}
          style={overridePadding ? { paddingLeft: "0" } : {}}
          key={pv.name}
        >
          {/* @ts-ignore */}
          <DropDownField
            required={pv.required}
            label={
              isInDataTable
                ? null
                : pv.required === true
                ? "*" + pv.label
                : pv.label
            }
            key={index}
            value={renderKey()}
            onChange={(e) => {
              let selected = e.target.options.selectedIndex;
              setArr(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? {
                        ...variable,
                        value: e.target.options[selected].accessKey,
                      }
                    : variable
                )
              );
              if (setVariableChanged) {
                setVariableChanged(true);
              }
              if (onBlur) {
                onBlur(pv);
              }
            }}
            options={selectOptions.map((option) => {
              return (
                <option accessKey={option.value} key={option.value}>
                  {option.key}
                </option>
              );
            })}
          />
        </Col>
      );
    }
    if (
      variableType === "text" &&
      selectOptions.length === 0 &&
      ((pv.maximum && pv.maximum <= 256) || !pv.maximum)
    ) {
      components.push(
        <Col
          sm={isInDataTable ? 12 : has3PerRow ? 4 : 6}
          style={overridePadding ? { paddingLeft: "0" } : {}}
          key={pv.name}
        >
          <TextField
            id={pv.id}
            required={pv.required}
            key={index}
            value={pv.value}
            label={
              isInDataTable
                ? null
                : pv.required === true
                ? "*" + pv.label
                : pv.label || ""
            }
            onChange={(e) => {
              setArr(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? { ...variable, value: e.target.value }
                    : variable
                )
              );
              if (setVariableChanged) setVariableChanged(true);
            }}
            onBlur={() => (onBlur ? onBlur(pv) : null)}
          />
        </Col>
      );
    }
    if (
      variableType === "text" &&
      selectOptions.length === 0 &&
      pv.maximum &&
      pv.maximum > 256
    ) {
      components.push(
        <Col
          sm={isInDataTable ? 12 : has3PerRow ? 4 : 6}
          style={overridePadding ? { paddingLeft: "0" } : {}}
          key={pv.name}
        >
          <MultipleLineFieldV2
            id={pv.id}
            required={pv.required}
            rows={3}
            key={index}
            value={pv.value}
            label={
              isInDataTable
                ? null
                : pv.required === true
                ? "*" + pv.label
                : pv.label || ""
            }
            onChange={(e) => {
              setArr(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? { ...variable, value: e.target.value }
                    : variable
                )
              );
              if (setVariableChanged) setVariableChanged(true);
            }}
            onBlur={() => (onBlur ? onBlur(pv) : null)}
          />
        </Col>
      );
    }
    if (variableType === "number" && selectOptions.length === 0) {
      components.push(
        <Col
          sm={isInDataTable ? 12 : has3PerRow ? 4 : 6}
          style={overridePadding ? { paddingLeft: "0" } : {}}
          key={pv.name}
        >
          <FormNumberFieldV2
            required={pv.required}
            key={index}
            value={pv.value}
            label={
              isInDataTable
                ? null
                : pv.required === true
                ? "*" + pv.label
                : pv.label || ""
            }
            min={pv.minimum}
            max={pv.maximum}
            onChange={(e) => {
              setArr(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? { ...variable, value: e.target.value }
                    : variable
                )
              );
              if (setVariableChanged) setVariableChanged(true);
            }}
            onBlur={() => (onBlur ? onBlur(pv) : null)}
          />
        </Col>
      );
    }
    if (
      variableType === "date" &&
      selectOptions.length === 0 &&
      pv.name !== "msa_date"
    ) {
      components.push(
        <Col
          sm={isInDataTable ? 12 : has3PerRow ? 4 : 6}
          style={overridePadding ? { paddingLeft: "0" } : {}}
          key={pv.name}
        >
          <DateField
            required={pv.required}
            key={index}
            value={pv.value}
            label={
              isInDataTable
                ? null
                : pv.required === true
                ? "*" + pv.label
                : pv.label || ""
            }
            onChange={(e) => {
              setArr(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? { ...variable, value: e.target.value }
                    : variable
                )
              );
              if (setVariableChanged) setVariableChanged(true);
            }}
            onBlur={() => (onBlur ? onBlur(pv) : null)}
          />
        </Col>
      );
    }
  });

  if (isLoading && !isInDataTable) {
    return <SmallSpinner />;
  } else if (!isLoading && arr.length === 0) {
    return null;
  } else if (isInDataTable) {
    return [components];
  } else {
    return (
      <div>
        {hasTopBorder && <hr style={{ color: "#d7d7d7" }} />}
        {hasSectionTitle && (
          <div className="text16Bold">User Defined Fields</div>
        )}
        <Row
          style={
            hasGreyBox
              ? {
                  background: "#FAFAFA",
                  padding: "16px",
                  margin: "16px 0",
                }
              : {}
          }
        >
          {[components]}
        </Row>
      </div>
    );
  }
}

export default ProjectVariablesV2;
