import {
  ListAccountConnectionsApiArg,
  V1PagingMeta,
  V1Relationships,
  useListAccountConnectionsQuery,
} from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import { AccountConnection } from "../../types/connection";

const useListAccountConnections = () => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data, error, isLoading, refetch } = useListAccountConnectionsQuery({
    slug: accountSlug,
  });

  let connections: AccountConnection[] = [];
  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  if (data?.data) {
    try {
      connections = data.data.map((connection) => {
        if (!connection.id) {
          throw new Error("Missing connection.id");
        }
        const isFileStorage = connection?.attributes?.service === "merge_dev";
        //@ts-ignore
        const storageProvider = connection?.attributes?.settings?.storage || "";

        return {
          id: connection.id,
          active: connection?.attributes?.active || false,
          service: !isFileStorage
            ? connection?.attributes?.service || ""
            : storageProvider || "merge_dev",
          host: !isFileStorage
            ? connection?.attributes?.host || ""
            : storageProvider || "",
          status: connection?.attributes?.status || "",
          lastSync: connection?.attributes?.["last-sync-at"] || "",
          opportunities: connection?.attributes?.opportunities,
          settings: connection?.attributes?.settings,
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    connections,
    connectionsError: error || customError,
    connectionsLoading: isLoading,
    pageMeta,
    refetch,
  };
};

export default useListAccountConnections;
