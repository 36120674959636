import FolderTree from "react-folder-tree";
import "react-folder-tree/dist/style.css";
import "./style.css";
import React, { useEffect, useState } from "react";
import { FileTreeData } from "./types/fileTreeData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import {
  faAngleDown,
  faAngleRight,
  faFileLines,
  faFolder,
  faFolderOpen,
  faFolderPlus,
} from "@fortawesome/pro-regular-svg-icons";
import API from "../../utils/API/API";
import { getPathname, snakeToText } from "@utils/helperFunctions";

interface FolderTreeProps {
  fileTreeData: FileTreeData;
  storageOptions: any;
  setFileTreeData: (state) => void;
  rootFolder: string;
  setRootFolder: (state) => void;
  allFolders: any[] | [];
  setAllFolders: (state) => void;
  allFiles: any[] | [];
  setAllFiles: (state) => void;
  setTreeData: (folders, files) => void;
  showCheckbox?: boolean;
  readOnly?: boolean;
  onNameClickPassed?: ({
    defaultOnClick,
    nodeData,
  }: {
    defaultOnClick: any;
    nodeData: any;
  }) => void;
  onChange?: (e: any) => void;
  DeleteIconPassed?: any;
  AddFileIconPassed?: any;
  FileIconPassed?: any;
  CaretRightIconPassed?: any;
  OpenFolderIconPassed?: any;
  FolderIconPassed?: any;
  EditIconPassed?: any;
  CaretDownIconPassed?: any;
  AddFolderIconPassed?: any;
  account_slug: string;
  indentPixels?: number;
  authorizationCode: string;
  selectedConnection?: any;
  selectedIntegration?: any;
  canCreateFolder?: boolean;
  canEditFolderName?: boolean;
  showFileTreeModal?: boolean;
  setShowFileTreeModal?: (boolState) => void;
  setSelectedIntegration?: (state) => void;
  setSelectedConnection?: (state) => void;
  folderLoading?: boolean;
  setFolderLoading?: (boolState) => void;
  selectedFolder?: null | { id: string; name: string };
  setSelectedFolder?: (state) => void;
  setOriginalSelectedFolder?: (state) => void;
}

function FolderTreeComponent({
  showCheckbox,
  readOnly,
  fileTreeData,
  onNameClickPassed,
  onChange,
  DeleteIconPassed,
  AddFileIconPassed,
  FileIconPassed,
  OpenFolderIconPassed,
  CaretRightIconPassed,
  AddFolderIconPassed,
  EditIconPassed,
  FolderIconPassed,
  CaretDownIconPassed,
  indentPixels,
  account_slug,
  authorizationCode,
  selectedConnection,
  selectedIntegration,
  showFileTreeModal,
  setShowFileTreeModal,
  setSelectedIntegration,
  setSelectedConnection,
  storageOptions,
  folderLoading,
  setFolderLoading,
  selectedFolder,
  setSelectedFolder,
  setOriginalSelectedFolder,
  canCreateFolder,
  canEditFolderName,
  rootFolder,
  setRootFolder,
  allFolders,
  setAllFolders,
  allFiles,
  setAllFiles,
  setTreeData,
}: FolderTreeProps) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const getFolderData = (newSelectedFolder) => {
    if (authorizationCode !== "") {
      API.Get(
        `${apiHost}/${account_slug}/v1/file-storages/${selectedConnection?.["id"]}/get-contents?filter[folder]=${newSelectedFolder.id}`,
        authorizationCode
      ).then((res) => {
        const data = res?.data?.data;
        const attr = res?.data?.data?.attributes;
        if (data?.length > 0) {
          const responseFolders = (attr?.folders || []).map((folder) => {
            folder = { ...folder, type: "folder", children: [] };
            return folder;
          });
          const responseFiles = (attr?.files || []).map((file) => {
            file = { ...file, type: "file" };
            return file;
          });
          const folders = responseFolders.concat(allFolders).map((f) => {
            if (f.id == newSelectedFolder.id) {
              f = {
                ...f,
                children: responseFolders.concat(responseFiles),
              };
            }
            return f;
          });
          const files = responseFiles.concat(allFiles);
          if (setAllFolders) {
            setAllFolders(folders);
          }

          if (setAllFiles) {
            setAllFiles(files);
          }
          if (setTreeData) {
            setTreeData(folders, files);
          }
        }
      });
    }
  };

  useEffect(() => {
    setTimeout(function () {
      if (showFileTreeModal) {
        let foundFolder = allFolders?.find((f) => f.id == selectedFolder?.id);
        if (!foundFolder) {
          foundFolder = {
            id: "0",
            name: rootFolder,
            file_url: `/${rootFolder}`,
            type: "folder",
          };
        }
        onNameClick(
          {
            defaultOnClick: () => null,
            nodeData: foundFolder,
          },
          true
        );
      }
    }, 100);
  }, [showFileTreeModal]);

  const onNameClick = (
    { defaultOnClick, nodeData },
    isFromModalStateChange
  ) => {
    defaultOnClick();
    if (onNameClickPassed) {
      onNameClickPassed(  { defaultOnClick, nodeData });
    }

    const { id, file_url, type, name } = nodeData;

    const folderNames = Array.from(
      document.getElementsByClassName("displayName")
    );
    folderNames.forEach((folderElement) => {
      if (
        folderElement.innerHTML.toLocaleLowerCase() == name.toLocaleLowerCase()
      ) {
        folderElement.classList.add("selectedFolder");
      } else {
        folderElement.classList.remove("selectedFolder");
      }
    });
    let newSelectedFolder = {
      id: id,
      name:
        id === "0" || name.toLocaleLowerCase() == rootFolder.toLocaleLowerCase()
          ? rootFolder
          : getPathname(file_url),
    };

    if (newSelectedFolder.id == selectedFolder?.id && !isFromModalStateChange) {
      return;
    }

    const foldersWithChildren = allFolders.map((folder) => {
      folder = {
        ...folder,
        children: (
          allFolders.filter((f) => f.parent_folder_id == folder.id) || []
        ).concat(
          allFiles.filter((file) => file?.["folder_id"] == folder.id) || []
        ),
      };
      return folder;
    });
    const foundFolderHasChildren = foldersWithChildren.find(
      (f) => f.id == nodeData.id
    );

    if (type === "folder") {
      if (setSelectedFolder) {
        setSelectedFolder(newSelectedFolder);
      }
      if (!foundFolderHasChildren && newSelectedFolder.name !== rootFolder) {
        getFolderData(newSelectedFolder);
      }
    } else {
      let folderOfFile = allFolders?.find(
        (folder) => folder?.["id"] == nodeData.folder_id
      );
      newSelectedFolder = {
        id: folderOfFile?.["id"] || "0",
        name: folderOfFile
          ? getPathname(folderOfFile?.["file_url"])
          : rootFolder,
      };
      if (setSelectedFolder) {
        setSelectedFolder(newSelectedFolder);
      }
    }
  };

  const postFolder = (folder) => {
    return API.Post(
      `${apiHost}/${account_slug}/v1/file-storages/${selectedConnection?.["id"]}/create-folder`,
      {
        filter: { folder: folder.parent_folder_id },
        data: {
          attributes: {
            name: folder.name,
          },
        },
      },
      authorizationCode
    );
  };

  const editFolderName = (folder) => {
    return API.Post(
      `${apiHost}/${account_slug}/v1/file-storages/${selectedConnection?.["id"]}/edit-folder`,
      {
        filter: { folder: folder.parent_folder_id },
        data: {
          attributes: {
            name: folder.name,
          },
        },
      },
      authorizationCode
    );
  };

  const checkName = (child) => {
    fileTreeData?.children.forEach((item) => {
      if (item.id == child.id && item.name !== child.name) {
        editFolderName(child).then((res) => {
          child.name = res.data.data.name;
        });
      }
      if (child?.children?.length > 0) {
        child.children.forEach((folder) => {
          checkName(folder);
        });
      }
    });
  };

  const onTreeStateChange = (e) => {
    e?.children?.forEach((child) => {
      const foldersWithNewFolderName = Array.from(
        new Set(
          e?.children
            ?.filter((f) => f.name.includes("new folder"))
            .concat(allFolders.filter((f) => f.name.includes("new folder")))
        )
      );
      if (!child.id) {
        child = {
          ...child,
          parent_folder_id: e.id,
          name:
            foldersWithNewFolderName.length === 0
              ? child.name
              : child.name + `(${foldersWithNewFolderName.length})`,
        };
        Array.from(document.getElementsByClassName(".displayName")).forEach(
          (el) => {
            if (el.innerHTML == "new folder") {
              el.innerHTML = child.name;
            }
          }
        );
        postFolder(child).then((res) => {
          const data = res?.data?.data?.attributes?.folders;
          const newlyAddedFolder = {
            ...data,
            file_url: data.folder_url,
            type: "folder",
            children: [],
          };
          setAllFolders([...allFolders, newlyAddedFolder]);
          onNameClick(
            {
              defaultOnClick: () => null,
              nodeData: newlyAddedFolder,
            },
            false
          );
        });
      }
      if (child?.children?.length > 0) {
        onTreeStateChange(child);
      }
      checkName(child);
    });
  };

  const DeleteIcon = (...args) => null;
  const AddFileIcon = (...args) => null;
  const CancelIcon = (...args) => null;
  const CaretRightIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    // custom event handler
    const handleClick = () => {
      getFolderData(nodeData);

      defaultOnClick();
    };

    // custom Style
    return <FontAwesomeIcon onClick={handleClick} icon={faAngleRight} />;
  };

  const CaretDownIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    // custom event handler
    const handleClick = () => {
      defaultOnClick();
    };

    // custom Style
    return <FontAwesomeIcon onClick={handleClick} icon={faAngleDown} />;
  };

  const FileIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    // custom event handler
    const handleClick = () => {
      defaultOnClick();
    };

    // custom Style
    return <FontAwesomeIcon onClick={handleClick} icon={faFileLines} />;
  };

  const FolderIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    // custom event handler
    const handleClick = () => {
      defaultOnClick();
    };

    // custom Style
    return <FontAwesomeIcon onClick={handleClick} icon={faFolder} />;
  };

  const OpenFolderIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    // custom event handler
    const handleClick = () => {
      defaultOnClick();
    };

    // custom Style
    return <FontAwesomeIcon onClick={handleClick} icon={faFolderOpen} />;
  };

  const AddFolderIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    // custom event handler
    const handleClick = (nodeData) => {
      defaultOnClick();
    };

    if (!canCreateFolder) {
      return null;
    } else {
      return (
        <FontAwesomeIcon
          onClick={(e) => handleClick(nodeData)}
          icon={faFolderPlus}
        />
      );
    }
  };

  const EditIcon = ({ onClick: defaultOnClick, nodeData }) => {
    const { path, name, checked, isOpen, ...restData } = nodeData;

    const handleClick = () => {
      defaultOnClick();
    };

    if (!canEditFolderName) {
      return null;
    } else {
      return <FontAwesomeIcon onClick={handleClick} icon={faPencil} />;
    }
  };
  let iconComponents: any = {
    DeleteIcon: DeleteIconPassed ? DeleteIconPassed : DeleteIcon,
    AddFileIcon: AddFileIconPassed ? AddFileIconPassed : AddFileIcon,
    FileIcon: FileIconPassed ? FileIconPassed : FileIcon,
    FolderIcon: FolderIconPassed ? FolderIconPassed : FolderIcon,
    CaretRightIcon: CaretRightIconPassed
      ? CaretRightIconPassed
      : CaretRightIcon,
    CaretDownIcon: CaretDownIconPassed ? CaretDownIconPassed : CaretDownIcon,
    AddFolderIcon: AddFolderIconPassed ? AddFolderIconPassed : AddFolderIcon,
    EditIcon: EditIconPassed ? EditIconPassed : EditIcon,
    OpenFolderIcon: OpenFolderIconPassed
      ? OpenFolderIconPassed
      : OpenFolderIcon,
  };
  if (!canCreateFolder && !canEditFolderName) {
    iconComponents = { ...iconComponents, CancelIcon };
  }
  return (
    <FolderTree
      data={fileTreeData}
      showCheckbox={showCheckbox}
      readOnly={readOnly}
      onNameClick={(e) => {
        onNameClick(
          { defaultOnClick: e.defaultOnClick, nodeData: e.nodeData },
          false
        );
      }}
      onChange={onChange}
      iconComponents={iconComponents}
      indentPixels={indentPixels}
    />
  );
}

export default FolderTreeComponent;
