import { useState, useEffect } from "react";
import "./styles/style.css";
import { useListAccountConnections, useListConnectedApps } from "./api";
import { ColumnDef } from "@tanstack/react-table";
import DataTable from "@components/DataTable";
import { AccountConnection } from "../types/connection";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  snakeToText,
  formatUnprocessibleResponse,
  handleAlert,
} from "@utils/helperFunctions";
import { formatDateAndTime } from "@utils/helpers";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import IntegrationTile from "@components/IntegrationTile/IntegrationTile";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import SearchField from "@components/Forms/SearchField/SearchField";
import { Form } from "react-bootstrap";
import { AvailableApp } from "../types";
import About from "../About";
import { useNavigate } from "react-router";
import { normalizeAppName } from "../common";
import { useDeleteAccountConnectionMutation } from "@generated";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";

const Show = (): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const navigate = useNavigate();
  const { connections, connectionsLoading, connectionsError, refetch } =
    useListAccountConnections();
  const { availableApps, appsLoading, appsError } = useListConnectedApps();
  interface Category {
    value: string;
    selected: boolean;
  }

  const [apps, setApps] = useState<AvailableApp[] | null>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([
    { value: "All", selected: true },
  ]);
  //Selected from Available Apps section
  const [selectedApp, setSelectedApp] = useState<AvailableApp | null>(null);
  //Selected for delete (checkboxes)
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [deleteConnection] = useDeleteAccountConnectionMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const connectionsTableColumns: ColumnDef<AccountConnection>[] = [
    {
      header: "Service",
      cell(props) {
        return props.row.original.service === "No connected apps found" ? (
          <>{props.row.original.service}</>
        ) : (
          <div
            data-testid={`selectedConnection-${props.row.index}`}
            className="connectionName"
            onClick={() => {
              const id = props.row.original.id;
              const app =
                availableApps.find(
                  (a) => a.service === props.row.original.service
                ) || availableApps.find((a) => a.service == "merge_dev");

              //pass along connected app
              navigate(`manage/${id}`, {
                state: app,
              });
            }}
          >
            {normalizeAppName(props.row.original.service)}
          </div>
        );
      },
    },
    {
      header: "Name",
      cell(props) {
        return <>{normalizeAppName(props.row.original.host)}</>;
      },
    },
    {
      header: "Status",
      cell(props) {
        const status = props.row.original.status;
        let color = "#8F8F8F";
        if (status === "Connected") color = "#63C4AB";
        if (status === "Connection Error") color = "#B90200";
        return (
          <>
            {status && (
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color, marginRight: "5px" }}
              />
            )}
            {status}
          </>
        );
      },
    },
    {
      header: "Last Sync",
      cell(props) {
        const date = props.row.original.lastSync;
        return <>{date ? formatDateAndTime(new Date(date)) : ""}</>;
      },
    },
  ];

  useEffect(() => {
    if (
      availableApps.length > 0 &&
      !appsLoading &&
      !apps &&
      !searchInput &&
      categories.filter((c) => c.value !== "All" && c.selected).length === 0
    ) {
      // create an array of categories {value: string; selected: boolean}[] from any categories that are found in the availableApps data
      const uniqueCategories = Array.from(
        new Set(
          availableApps.flatMap((item) =>
            item.categories.map((category) => category.category || "")
          )
        )
      ).map((category) => {
        return { value: category, selected: false };
      });
      setCategories([{ value: "All", selected: true }, ...uniqueCategories]);
      setApps(availableApps);
    }
  }, [availableApps]);

  useEffect(() => {
    if (selectedApp) {
      // Scroll to the top of the page whenever an app is selected
      window.scrollTo(0, 0);
    } else {
      //Otherwise refetch latest for connections table
      refetch();
    }
  }, [selectedApp]);

  useEffect(() => {
    refetch();
  }, []);

  //This function sets the displayed apps to those that meet BOTH the search input and selected categories criteria
  const filterApps = (categories: Category[], searchInput: string) => {
    //Create a string array of the currently selected categories to compare each app against when filtering
    const selectedCategories: string[] = categories
      .filter((c) => c.selected)
      .map((c) => c.value);

    let filteredByCategory: AvailableApp[] = availableApps;

    //If "All" is not selected, filter by active categories
    if (!selectedCategories.includes("All")) {
      filteredByCategory = availableApps.filter((app) => {
        //Create string array from the categories on each app to compare against selected categories
        const appCategories: string[] = app.categories.map(
          (c) => c.category || ""
        );
        return appCategories.some((c) => selectedCategories.includes(c));
      });
    }

    // only return apps that match search criteria
    setApps(
      filteredByCategory.filter((app) =>
        normalizeAppName(app.service)
          .toLowerCase()
          .includes(searchInput?.toLowerCase() || "")
      )
    );
  };

  const handleCategoryChange = (selectedCategory) => {
    //Whenever "All" is selected, the rest should be unselected.
    //Multiple categories can be selected at once, but if all others become unselected it defaults back to "All" being selected
    const otherCategoriesSelected = categories.some(
      (c) =>
        c.value !== "All" && c.value !== selectedCategory.value && c.selected
    );
    const allSelected = categories?.find((c) => c.value == "All")?.selected;
    let selected = categories.filter((c) => c.selected);
    //If deselecting a non-"All" category when others are still selected OR selecting a non-"All" category for the first time --> set to true and deselect "All"
    if (
      selectedCategory.value !== "All" &&
      (otherCategoriesSelected || allSelected)
    ) {
      selected = categories.map((c) =>
        c.value === selectedCategory.value
          ? { ...c, selected: !selectedCategory.selected }
          : c.value === "All"
          ? {
              ...c,
              selected: false,
            }
          : c
      );
    } else {
      selected = categories.map((c) =>
        c.value === "All" ? { ...c, selected: true } : { ...c, selected: false }
      );
    }
    setCategories(selected);
    filterApps(selected, searchInput);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if ((e.key === "Enter" || e.type === "submit") && searchInput !== "") {
      filterApps(categories, searchInput);
    }
  };

  const getDeleteConnectionsModalBody = () => {
    const count = selectedIds.length;
    const connectionsToDelete = selectedIds.map((id) =>
      connections.find((c) => Number(c.id) === Number(id))
    );
    // Filter out any undefined entries in case a connection wasn't found
    const validConnectionsToDelete = connectionsToDelete.filter(Boolean);

    return loading ? (
      <SmallSpinner />
    ) : (
      <div>
        <p>
          <strong>
            You are about to delete {count} connection
            {count > 1 ? "s" : ""}:
          </strong>
        </p>
        <ul>
          {validConnectionsToDelete.map(
            (connection) =>
              connection && (
                <li key={connection.id}>
                  {normalizeAppName(connection.service)}
                </li>
              ) // This ensures connection is not undefined
          )}
        </ul>
        <p>This cannot be undone. Do you wish to proceed?</p>
      </div>
    );
  };

  const handleDelete = () => {
    if (selectedIds.length > 0) {
      const promises = selectedIds.map((id) => {
        return deleteConnection({
          slug: accountSlug,
          id: Number(id),
        });
      });

      Promise.all(promises)
        .then((res) => {
          refetch();
          setSelectedIds([]);
          setShowDeleteModal(false);
          //@ts-ignore
          if (res?.[0]?.error) {
            //@ts-ignore
            let error = res?.[0]?.error?.data?.errors?.[0]?.meta?.exception;
            setErrorMessage(`Something went wrong. ${error || ""}`);
            setShowFailAlert(true);
          } else {
            setSuccessMessage(
              selectedIds.length <= 1
                ? "Connection deleted."
                : `${selectedIds.length} connections deleted.`
            );
            setShowSuccessAlert(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage(formatUnprocessibleResponse(error, "connection"));
          setShowFailAlert(true);
        });
    }
  };

  return (
    <div data-testid="connectionsPage" className="connectionsPage">
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {selectedApp && (
        <About app={selectedApp} setSelectedApp={setSelectedApp} />
      )}
      {!selectedApp && (
        <>
          <div className="connectedApps">
            <h2>Connected Apps</h2>
            {selectedIds.length ? (
              <div className={"selectedConnections"}>
                <div className={"selectedMessage"}>
                  {selectedIds.length} Selected
                </div>
                {/* Save & Delete selected products buttons */}
                <div className={"selectedIcons"}>
                  <div>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Delete connection(s)</Tooltip>}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        onClick={() => setShowDeleteModal(true)}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            ) : (
              <div className={connectionsLoading ? "" : "placeholder"}></div>
            )}
            {connectionsLoading ? (
              <SmallSpinner />
            ) : (
              <DataTable
                data={
                  connections?.length
                    ? connections
                    : [
                        {
                          id: 0,
                          service: "No connected apps found",
                        },
                      ]
                }
                columns={connectionsTableColumns}
                bordered
                striped
                hover
                selectable={connections.length ? true : false}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            )}
          </div>
          <div className="availableApps">
            <h2>Available Apps</h2>
            <div className="searchBar">
              <Form onSubmit={handleSearch}>
                <FormFieldLabel label="Search Apps" className="" />
                <SearchField
                  placeholder="Search connected apps"
                  onChange={(e) => {
                    const input = e.target.value;
                    setSearchInput(input);
                    if (input === "") {
                      categories.find((c) => c.value == "All")?.selected
                        ? setApps(availableApps)
                        : filterApps(categories, "");
                    }
                  }}
                  value={searchInput}
                  onClick={() => {
                    setSearchInput("");
                    filterApps(categories, "");
                  }}
                  disabled={connectionsLoading}
                  fullWidth
                />
              </Form>
            </div>
            {categories.length && (
              <div className="categories">
                <strong>Category:</strong>
                {categories.map((category) => (
                  <div
                    className={`category ${
                      category.selected ? "selected" : ""
                    }`}
                    key={category.value}
                    onClick={() => {
                      handleCategoryChange(category);
                    }}
                  >
                    {category.value === "All"
                      ? category.value
                      : category.value.toUpperCase() || ""}
                  </div>
                ))}
              </div>
            )}
            {connectionsLoading ? (
              <SmallSpinner />
            ) : (
              <div className="apps">
                {apps?.length ? (
                  apps.map((app) => (
                    <IntegrationTile
                      logo={app.logo}
                      name={normalizeAppName(app.service)}
                      summary={app.summary}
                      onClick={() => setSelectedApp(app)}
                      key={app.id}
                    />
                  ))
                ) : (
                  <div className="empty">No apps found.</div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <ScopeStackModal
        modalTitle="Delete Connections"
        modalBody={getDeleteConnectionsModalBody()}
        button1Text="Cancel"
        handleButton1Click={() => setShowDeleteModal(false)}
        button2Text={"Delete"}
        handleButton2Click={() => {
          setLoading(true);
          handleDelete();
        }}
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        deleteModal={true}
        button2Disabled={loading}
      />
    </div>
  );
};

export default Show;
