import { useEffect, useState } from "react";
import "./style";
import { Form, Row } from "react-bootstrap";
import SearchField from "@components/Forms/SearchField/SearchField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsFilter,
  faCopy,
  faTrashCan,
  faCheck,
  faDownload,
  faClockRotateLeft,
} from "@fortawesome/pro-solid-svg-icons";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import { Tab, Tabs } from "react-bootstrap";
import { Button } from "@components/Button";
import { UserSelectionsState, Option } from "./types";
import ProfessionalServicesTable from "./common/ProfessionalServicesTable";
import ToggleOnOff from "@components/Buttons/ToggleOnOff/ToggleOnOff";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  useRestoreServiceMutation,
  useDuplicateServiceMutation,
  useDeleteServiceMutation,
  useDownloadServiceMutation,
  useActivateServiceMutation,
  ListServicesForAccountApiArg,
  useCreateDataImportMutation,
  useCreateUserFilterMutation,
  useUpdateUserFilterMutation,
  V1UserFilterResource,
} from "@generated";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import {
  handleAlert,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import useOauth from "@utils/customHooks/useOauth";
import API from "../../../../utils/API/API";
import ServiceUsedInSlideout from "./common/ServiceUsedInSlideout/ServiceUsedInSlideout";
import ServiceFilters from "./common/ServiceFilters";
import fileToBase64 from "@utils/fileToBase64";
import { FileUploadDragAndDrop } from "@components/FormsV2/FileUploadDragAndDrop";
import { useNavigate } from "react-router";
import useFlags from "@common/hooks/useFlags";
import useWhoAmI from "../../../Account/EditAccount/api/useWhoAmI";
import { filtersChanged } from "./common/helpers";
import {
  useListServiceFiltersForAccount,
  useListPhasesForAccount,
  useListLOBsForAccount,
  useListServiceCategoriesForAccount,
  useListQuestionnairesForAccount,
  useListBlueprintsForAccount,
} from "./api";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";

function Show({ account, servicePermission, serviceType }) {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { professionalServicesReactManageEnable } = useFlags();
  const { data: user, error: userError, isLoading: userLoading } = useWhoAmI();
  const navigate = useNavigate();
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [authorizationCode] = useOauth();
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [searchInput, setSearchInput] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<"services" | "analytics">("services");
  const [filterParams, setFilterParams] = useState<
    ListServicesForAccountApiArg["filter"]
  >({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [servicesCount, setServicesCount] = useState({
    active: null,
    pending: null,
    archived: null,
  });
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [refetchServices, setRefetchServices] = useState<boolean>(false);
  const [previewSlideoutOpen, setPreviewSlideoutOpen] =
    useState<boolean>(false);
  const [previewServiceId, setPreviewServiceId] = useState<string>("");
  const [filterSlideoutOpen, setFilterSlideoutOpen] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [applyFilters, setApplyFilters] = useState<boolean>(false);
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [userSelections, setUserSelections] = useState<UserSelectionsState>({
    blueprints: [],
    categories: [],
    endDateCreated: "",
    endDateUpdated: "",
    frequency: [],
    lobs: [],
    phases: [],
    questionnaires: [],
    startDateCreated: "",
    startDateUpdated: "",
  });
  const { filters, filtersLoading, filtersFetching } =
    useListServiceFiltersForAccount(serviceType);
  const [filterOptions, setFilterOptions] = useState<Option[]>([]);

  const { phases, phasesLoading } = useListPhasesForAccount();
  const [phaseOptions, setPhaseOptions] = useState<Option[]>([]);

  const { lobs, lobsLoading } = useListLOBsForAccount();
  const [lobOptions, setLobOptions] = useState<Option[]>([]);

  const { categories, categoriesLoading } = useListServiceCategoriesForAccount(
    userSelections.lobs
  );
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);

  const frequencyOptions: Option[] = [
    { label: "One Time", value: "one_time" },
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
    { label: "Yearly", value: "yearly" },
  ];

  const { questionnaires, questionnairesLoading } =
    useListQuestionnairesForAccount();
  const [questionnaireOptions, setQuestionnaireOptions] = useState<Option[]>(
    []
  );

  const { blueprints, blueprintsLoading } = useListBlueprintsForAccount();
  const [blueprintOptions, setBlueprintOptions] = useState<Option[]>([]);
  const [savedFilterApplied, setSavedFilterApplied] = useState(false);

  useEffect(() => {
    const route =
      serviceType === "professional_services"
        ? "/admin/tasks"
        : "/admin/managed_services";
    window.history.pushState({ page: route }, "Tasks", route);
  }, []);

  useEffect(() => {
    if (lobs.length && !lobOptions.length) {
      setLobOptions(lobs);
    }
  }, [lobs]);

  useEffect(() => {
    if (JSON.stringify(categories) !== JSON.stringify(categoryOptions)) {
      setCategoryOptions(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (phases.length && !phaseOptions.length) {
      setPhaseOptions(phases);
    }
  }, [phases]);

  useEffect(() => {
    if (blueprints.length && !blueprintOptions.length) {
      setBlueprintOptions(blueprints);
    }
  }, [blueprints]);

  useEffect(() => {
    if (questionnaires.length && !questionnaireOptions.length) {
      setQuestionnaireOptions(questionnaires);
    }
  }, [questionnaires]);

  const [activateService] = useActivateServiceMutation();
  const [duplicateService] = useDuplicateServiceMutation();
  const [restoreService] = useRestoreServiceMutation();
  const [deleteService] = useDeleteServiceMutation();
  const [downloadService] = useDownloadServiceMutation();
  const [importService] = useCreateDataImportMutation();
  const [createFilter] = useCreateUserFilterMutation();
  const [updateFilter] = useUpdateUserFilterMutation();

  useEffect(() => {
    if (filters.length) {
      const activeFilter = filters.filter(
        (f) => f?.serviceType === serviceType
      )?.[0]?.filters;
      if (activeFilter) {
        const formatResource = (
          ids: string[] | undefined,
          resourceItems: { label: string; value: string }[]
        ) => {
          if (!ids) return [];
          return (
            ids?.map((id) => {
              return (
                resourceItems.find((item) => item.value == id) || {
                  label: "",
                  value: "",
                }
              );
            }) || []
          );
        };
        const formattedFilter = {
          blueprints: formatResource(activeFilter.blueprints, blueprints),
          categories: formatResource(activeFilter.categories, categories),
          endDateCreated: activeFilter?.endDateCreated || "",
          endDateUpdated: activeFilter?.endDateUpdated || "",
          frequency: formatResource(activeFilter.frequency, frequencyOptions),
          lobs: formatResource(activeFilter.lobs, lobs),
          phases: formatResource(activeFilter.phases, phases),
          questionnaires: formatResource(
            activeFilter.questionnaires,
            questionnaires
          ),
          startDateCreated: activeFilter?.startDateCreated || "",
          startDateUpdated: activeFilter?.startDateUpdated || "",
        };

        const optionsLoaded =
          !blueprintsLoading &&
          !categoriesLoading &&
          !phasesLoading &&
          !lobsLoading &&
          !questionnairesLoading;

        if (
          optionsLoaded &&
          !savedFilterApplied &&
          (JSON.stringify(userSelections) !== JSON.stringify(formattedFilter) ||
            Object.values(formattedFilter).every(
              (value) =>
                (Array.isArray(value) && value.length === 0) ||
                (typeof value === "string" && value === "")
            ))
        ) {
          setUserSelections(formattedFilter as UserSelectionsState);
          setApplyFilters(true);

          setSavedFilterApplied(true);
        }
      }
    }
  }, [filters]);

  useEffect(() => {
    let count = 0;
    for (let key in userSelections) {
      if (
        Array.isArray(userSelections[key]) &&
        userSelections[key].length > 0
      ) {
        count++;
      }
    }
    if (userSelections.startDateCreated || userSelections.endDateCreated)
      count++;
    if (userSelections.startDateUpdated || userSelections.endDateUpdated)
      count++;

    setFilterCount(count);
  }, [userSelections]);

  useEffect(() => {
    if (applyFilters) {
      if (
        (userSelections.startDateCreated && !userSelections.endDateCreated) ||
        (!userSelections.startDateCreated && userSelections.endDateCreated) ||
        (userSelections.startDateUpdated && !userSelections.endDateUpdated) ||
        (!userSelections.startDateUpdated && userSelections.endDateUpdated)
      ) {
        setErrorMessage("Please provide a start and end date");
        setShowFailAlert(true);
        setApplyFilters(false);
        return;
      }
      let params: ListServicesForAccountApiArg["filter"] = {};
      if (userSelections.lobs.length)
        params.lob = userSelections.lobs.map((item) => item.value);
      if (userSelections.categories.length)
        params["service-category"] = userSelections.categories.map(
          (item) => item.value
        );
      if (userSelections.frequency.length)
        params["payment-frequency"] = userSelections.frequency.map(
          (item) => item.value
        );
      if (userSelections.phases.length)
        //@ts-ignore
        params.phase = userSelections.phases.map((item) => item.value);
      if (userSelections.blueprints.length)
        //@ts-ignore
        params.blueprint = userSelections.blueprints.map((item) => item.value);
      if (userSelections.questionnaires.length)
        //@ts-ignore
        params.questionnaire = userSelections.questionnaires.map(
          (item) => item.value
        );
      if (userSelections.startDateCreated)
        params["created-at.after"] = userSelections.startDateCreated;
      if (userSelections.endDateCreated)
        params["created-at.before"] = userSelections.endDateCreated;
      if (userSelections.startDateUpdated)
        params["updated-at.after"] = userSelections.startDateUpdated;
      if (userSelections.endDateUpdated)
        params["updated-at.before"] = userSelections.endDateUpdated;

      let data: V1UserFilterResource = {
        type: "user-filters",
        attributes: {
          "data-type": serviceType,
          filters: {
            lobs: params?.lob || [],
            categories: params?.["service-category"] || [],
            frequency: params?.["payment-frequency"] || [],
            phases: params?.phase || [],
            blueprints: params?.blueprint || [],
            questionnaires: params?.questionnaire || [],
            startDateCreated: params?.["created-at.after"] || "",
            endDateCreated: params?.["created-at.before"] || "",
            startDateUpdated: params?.["updated-at.after"] || "",
            endDateUpdated: params?.["updated-at.before"] || "",
          },
        },
        relationships: {
          user: { data: { id: Number(user?.id), type: "users" } },
          account: {
            data: {
              type: "accounts",
              id: Number(account.data?.attributes["account-id"]),
            },
          },
        },
      };

      // If no existing saved filter -- Post, else Patch
      const appliedFilter = filters.filter(
        (f) => f.serviceType === serviceType
      )?.[0];

      if (!appliedFilter) {
        createFilter({
          slug: accountSlug,
          body: { data },
        });
      } else if (filtersChanged(userSelections, appliedFilter.filters)) {
        data.id = Number(appliedFilter.value);
        updateFilter({
          slug: accountSlug,
          body: { data },
          id: Number(appliedFilter.value),
        });
      }

      setFilterParams(params);
      setFilterSlideoutOpen(false);
      setApplyFilters(false);
    }
  }, [applyFilters]);

  useEffect(() => {
    if (clearAll && !filterSlideoutOpen) {
      setUserSelections({
        lobs: [],
        categories: [],
        frequency: [],
        phases: [],
        blueprints: [],
        questionnaires: [],
        startDateCreated: "",
        endDateCreated: "",
        startDateUpdated: "",
        endDateUpdated: "",
      });
      //UPDATE filter params
      setFilterParams({ name: searchInput || undefined });
      setClearAll(false);
      setApplyFilters(true);
    }
  }, [filterSlideoutOpen]);

  const hasEntitlement = (entitlementToCheck: string): boolean => {
    const entitlements = account?.data?.attributes?.entitlements ?? [];

    return entitlements.some((entitlement) => {
      return entitlementToCheck === entitlement;
    });
  };

  const [cta, setCTA] = useState<string>("Archive");
  let ctaIcons =
    currentTab === 1
      ? [
          { label: "Archive", icon: faTrashCan },
          { label: "Duplicate", icon: faCopy },
          { label: "Download", icon: faDownload },
        ]
      : currentTab === 2
      ? [
          { label: "Approve", icon: faCheck },
          { label: "Archive", icon: faTrashCan },
          { label: "Download", icon: faDownload },
        ]
      : [
          { label: "Un-Archive", icon: faClockRotateLeft },
          { label: "Download", icon: faDownload },
        ];
  if (servicePermission !== "manage") {
    ctaIcons = ctaIcons.filter(
      (icon) => icon.label !== "Archive" && icon.label !== "Un-Archive"
    );
  }
  if (servicePermission !== "create" && servicePermission !== "manage") {
    ctaIcons = ctaIcons.filter((icon) => icon.label !== "Duplicate");
  }
  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchName(searchInput);
  };

  const searchName = (input: string) => {
    setFilterParams({ ...filterParams, name: input });
  };

  const handleTabSelect = (eventKey) => {
    setCurrentTab(Number(eventKey));
    setSelectedIds([]);
  };

  const getToolTip = (msg) => (
    <Tooltip id={msg.split(" ").join("")}>{msg}</Tooltip>
  );

  const handleCTAClick = (action) => {
    if (action !== "Download") {
      const dir = {
        Archive: deleteService,
        Duplicate: duplicateService,
        Download: downloadService,
        "Un-Archive": restoreService,
        Approve: activateService,
      };

      const promises = selectedIds.map((id) => {
        const data = { id: Number(id), type: "services" };
        return dir[action]({
          slug: accountSlug,
          id: Number(id),
          body: action === "Archive" ? undefined : { data },
        });
      });

      if (promises.length) {
        Promise.all(promises)
          .then((res) => {
            if (res?.[0]?.error) {
              setErrorMessage(
                res[0].error?.data?.exception || "Something went wrong"
              );
              setShowModal(false);
              setShowFailAlert(true);
              return;
            }
            setRefetchServices(true);
            const pastTenseAction = action.toLowerCase() + "d";
            setSuccessMessage(
              selectedIds.length <= 1
                ? `Service has been ${pastTenseAction}.`
                : `${selectedIds.length} services ${pastTenseAction}.`
            );
            setShowSuccessAlert(true);
            setShowModal(false);
          })
          .catch((error) => {
            setErrorMessage(formatUnprocessibleResponse(error, "service"));
            setShowFailAlert(true);
            setShowModal(false);
          });
      }
    } else if (action === "Download") {
      selectedIds.forEach((id, i) => {
        const data = { data: { id, type: "services" } };
        API.Post(
          `${apiHost}/${accountSlug}/v1/services/${id}/download`,
          data,
          authorizationCode
        ).then((res) => {
          // Response.data should contain a comma-separated string
          const csvData = res?.data;

          //Error handling
          if (!csvData || typeof csvData !== "string") {
            setErrorMessage("Something went wrong dowloading a service.");
            setShowFailAlert(true);
          } else {
            // Create a Blob from the CSV data
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const a = document.createElement("a");
            a.href = url;
            a.download = `service-${id}.csv`;

            // Append the link to the document body
            document.body.appendChild(a);
            // Programmatically click the link to trigger the download
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            if (i === selectedIds.length - 1) {
              setSuccessMessage(
                selectedIds.length <= 1
                  ? "Service downloaded."
                  : `${selectedIds.length} services downloaded.`
              );
              setShowSuccessAlert(true);
            }
          }

          if (i === selectedIds.length - 1) {
            setShowModal(false);
          }
        });
      });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!showModal) {
      setSelectedIds([]);
      setLoading(false);
    }
  }, [showModal]);

  const getModalBody = (cta) => {
    let bodyText = "Are you sure you want to continue?";
    const multiple = selectedIds.length > 1;
    if (cta === "Archive") {
      bodyText = `${
        multiple ? "These services" : "This service"
      } will no longer be available in the project editor and will be moved to the archived tab. ${
        multiple ? "They" : "It"
      } will also be removed from any associated blueprints. ${
        multiple ? "They" : "It"
      } can be restored at any time.`;
    }
    if (cta === "Approve" || cta === "Un-Archive") {
      bodyText = `${
        multiple ? "These services" : "This service"
      } will be available in the project editor for consumption and will be moved to the active tab.`;
    }

    return loading ? (
      <SmallSpinner />
    ) : (
      <div className="modalBody">
        <p>
          <strong>{`You are about to ${cta.toLowerCase()} ${
            selectedIds.length
          } service${selectedIds.length > 1 ? "s" : ""}.`}</strong>
        </p>
        <p>{bodyText}</p>
      </div>
    );
  };

  const handleFileSelect = (file: File) => {
    if (
      !file.type.includes("csv") &&
      !file.type.includes("officedocument.spreadsheet")
    ) {
      setErrorMessage("The uploaded file must be in csv or xlsx format.");
      setShowFailAlert(true);
    } else {
      setUploadedFile(file);
    }
  };

  const getImportModalBody = () => {
    return importLoading ? (
      <SmallSpinner />
    ) : (
      <div>
        <div className="instructionalText seafoamText bold">
          * Upload a{" "}
          {serviceType === "professional_services" ? "Professional" : "Managed"}{" "}
          Service (CSV or Excel)
        </div>
        {uploadedFile ? (
          <div>
            <span>
              {uploadedFile.name}{" "}
              <FontAwesomeIcon
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
                icon={faTrashCan}
                onClick={() => setUploadedFile(null)}
              />
            </span>
          </div>
        ) : (
          <FileUploadDragAndDrop handleFileSelect={handleFileSelect} />
        )}
      </div>
    );
  };

  const handleCloseImportModal = () => {
    setUploadedFile(null);
    setShowImportModal(false);
    setImportLoading(false);
  };

  const handleFailResponse = (msg) => {
    setErrorMessage(`${msg || "Something went wrong importing this file"}`);
    setShowFailAlert(true);
    handleCloseImportModal();
  };

  const importServiceData = async () => {
    if (uploadedFile) {
      try {
        const encodedFile = await fileToBase64(uploadedFile);

        const data = {
          type: "data-imports",
          attributes: {
            "import-type":
              serviceType === "managed_services"
                ? serviceType
                : "standard_services",
            "context-type": "Account" as "Account",
            "context-id": account.data?.attributes?.["account-id"] || "",
            "data-file": encodedFile,
          },
          relationships: {
            account: {
              data: {
                type: "accounts",
                id: account.data?.attributes["account-id"],
              },
            },
          },
        };

        if (encodedFile) {
          importService({ slug: accountSlug, body: { data } })
            .then((res) => {
              //@ts-ignore
              const id = res?.data?.data?.id;
              if (id) {
                window.location.replace(`${appHost}/admin/imports/${id}/edit`);
              } else {
                handleFailResponse("");
              }
            })
            .catch((error) => {
              handleFailResponse(error?.data?.errors?.[0]?.detail);
            });
        } else {
          handleFailResponse("");
        }
      } catch (error) {
        handleFailResponse("");
      }
    } else {
      handleFailResponse("");
    }
  };

  return filtersLoading ||
    filtersFetching ||
    phasesLoading ||
    lobsLoading ||
    blueprintsLoading ||
    questionnairesLoading ||
    categoriesLoading ? (
    <ScopeStackSpinner />
  ) : (
    <div className="profServicesV2">
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <div className="headerContainer">
        <h2>{`${
          serviceType === "professional_services" ? "Professional" : "Managed"
        } Services`}</h2>
        {servicePermission === "none" || servicePermission === "view" ? null : (
          <div className="actionBtns">
            <div className="importBtn">
              <Button onClick={() => setShowImportModal(true)}>Import</Button>
            </div>
            <div className="addBtn">
              <Button
                onClick={() => {
                  if (professionalServicesReactManageEnable) {
                    navigate("new");
                  } else {
                    window.location.replace(
                      `${appHost}/admin/${
                        serviceType === "professional_services"
                          ? "tasks"
                          : "managed_services"
                      }/new`
                    );
                  }
                }}
              >
                Add a Service
              </Button>
            </div>
          </div>
        )}
      </div>
      <Row className="searchAndFilter">
        {selectedIds.length > 0 && (
          <div className="selectedServices">
            <div className="selectedMessage">{selectedIds.length} Selected</div>
            <div className="selectedIcons">
              {ctaIcons.map((icon, i) => (
                <OverlayTrigger
                  placement="bottom"
                  overlay={getToolTip(icon.label)}
                  key={i}
                >
                  <FontAwesomeIcon
                    icon={icon.icon}
                    onClick={() => {
                      setCTA(icon.label);
                      setShowModal(true);
                    }}
                    className={`${icon.label.toLowerCase()} ${
                      i !== ctaIcons.length - 1 ? "icon" : ""
                    }`}
                  />
                </OverlayTrigger>
              ))}
            </div>
          </div>
        )}
        <div className={`search ${selectedIds.length > 0 ? "selected" : ""}`}>
          <FormFieldLabel label="Search Services" className={undefined} />
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(e);
              }
            }}
            onSubmit={handleSearch}
          >
            <SearchField
              placeholder="Enter service name"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
              onClick={(e) => {
                setSearchInput("");
                searchName("");
              }}
              fullWidth
            />
          </Form>
        </div>
        <div className="filter">
          <FontAwesomeIcon
            icon={faBarsFilter}
            onClick={() => setFilterSlideoutOpen(true)}
            className={`${filterCount ? "activeFilters" : ""}`}
          />
          {filterCount ? (
            <span className="activeFilters">{`${filterCount}`} Active</span>
          ) : null}
        </div>
      </Row>
      {serviceType === "professional_services" &&
        hasEntitlement("psa-integration") && (
          <div className="toggleContainer">
            <ToggleOnOff
              initialValue={true}
              onToggle={(e) => setMode(e ? "services" : "analytics")}
              button1Text="Services"
              button2Text="Analytics"
            />
          </div>
        )}
      <div className="tabs">
        <Tabs
          defaultActiveKey={1}
          id="uncontrolled-tab"
          className="mb-3"
          onSelect={handleTabSelect}
        >
          <Tab
            eventKey={1}
            title={`Active ${
              servicesCount?.active !== null && currentTab == 1
                ? `(${servicesCount?.active})`
                : ""
            }`}
            tabClassName="profServicesTab"
            className="profServicesTabContent activeServices"
          >
            {currentTab === 1 && (
              <ProfessionalServicesTable
                serviceType={serviceType}
                tab={"active"}
                filterParams={filterParams}
                mode={mode}
                servicesCount={servicesCount}
                setServicesCount={setServicesCount}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                refetchServices={refetchServices}
                setRefetchServices={setRefetchServices}
                currentTab={currentTab}
                setPreviewSlideoutOpen={setPreviewSlideoutOpen}
                setPreviewServiceId={setPreviewServiceId}
                manageFlag={professionalServicesReactManageEnable}
              />
            )}
          </Tab>
          {servicePermission === "manage" ? (
            <Tab
              eventKey={2}
              title={`Pending ${
                servicesCount?.pending !== null && currentTab == 2
                  ? `(${servicesCount?.pending})`
                  : ""
              }`}
              tabClassName="profServicesTab"
              className="profServicesTabContent pendingServices"
            >
              {currentTab === 2 && (
                <ProfessionalServicesTable
                  serviceType={serviceType}
                  tab={"pending"}
                  filterParams={filterParams}
                  mode={mode}
                  servicesCount={servicesCount}
                  setServicesCount={setServicesCount}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  refetchServices={refetchServices}
                  setRefetchServices={setRefetchServices}
                  currentTab={currentTab}
                  setPreviewSlideoutOpen={setPreviewSlideoutOpen}
                  setPreviewServiceId={setPreviewServiceId}
                  manageFlag={professionalServicesReactManageEnable}
                />
              )}
            </Tab>
          ) : null}
          {servicePermission === "manage" ? (
            <Tab
              eventKey={3}
              title={`Archived ${
                servicesCount?.archived !== null && currentTab == 3
                  ? `(${servicesCount?.archived})`
                  : ""
              }`}
              tabClassName="profServicesTab"
              className="profServicesTabContent archivedServices"
            >
              {currentTab === 3 && (
                <ProfessionalServicesTable
                  serviceType={serviceType}
                  tab={"archived"}
                  filterParams={filterParams}
                  mode={mode}
                  servicesCount={servicesCount}
                  setServicesCount={setServicesCount}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  refetchServices={refetchServices}
                  setRefetchServices={setRefetchServices}
                  currentTab={currentTab}
                  setPreviewSlideoutOpen={setPreviewSlideoutOpen}
                  setPreviewServiceId={setPreviewServiceId}
                  manageFlag={professionalServicesReactManageEnable}
                />
              )}
            </Tab>
          ) : null}
        </Tabs>
        <ScopeStackModal
          show={showModal}
          modalTitle={`${cta} Services`}
          modalBody={getModalBody(cta)}
          button1Text="Cancel"
          handleButton1Click={handleCancel}
          button2Text={cta}
          handleButton2Click={() => {
            setLoading(true);
            handleCTAClick(cta);
          }}
          handleClose={handleCancel}
        />
        <ScopeStackModal
          show={showImportModal}
          modalTitle="Import a Service"
          modalBody={getImportModalBody()}
          button1Text="Cancel"
          handleButton1Click={handleCloseImportModal}
          button2Text={"Import"}
          handleButton2Click={() => {
            setImportLoading(true);
            importServiceData();
          }}
          button2Disabled={!uploadedFile || importLoading}
          handleClose={handleCloseImportModal}
        />
        {previewServiceId && (
          <ServiceUsedInSlideout
            serviceId={previewServiceId}
            setServiceId={setPreviewServiceId}
            slideoutOpen={previewSlideoutOpen}
            setSlideoutOpen={setPreviewSlideoutOpen}
          />
        )}
        <ServiceFilters
          serviceType={serviceType}
          userSelections={userSelections}
          setUserSelections={setUserSelections}
          slideoutOpen={filterSlideoutOpen}
          setSlideoutOpen={setFilterSlideoutOpen}
          filterCount={filterCount}
          onSave={() => setApplyFilters(true)}
          clearAll={clearAll}
          setClearAll={setClearAll}
          options={{
            blueprintOptions,
            lobOptions,
            categoryOptions,
            phaseOptions,
            frequencyOptions,
            questionnaireOptions,
          }}
        />
      </div>
    </div>
  );
}

export default Show;
