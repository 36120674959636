import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import { useListProjectCollaboratorsQuery } from "@generated";
import { Teammate } from "../types/Teammate";

const useListCollaborators = ({ pageSize, pageNumber, projectId }) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);

  let customError: string | null = null;
  let pageMeta = {
    recordCount: 0,
    pageCount: 0,
  };

  const { data, error, isLoading, refetch, isFetching } =
    useListProjectCollaboratorsQuery({
      slug: accountSlug,
      page: {
        number: pageNumber,
        size: pageSize,
      },
      include: ["project", "user"],
      filter: {
        active: true,
        project: projectId,
      },
    });

  let teammates: Teammate[] = [];
  const project = data?.included?.find(
    (item) => item.type === "projects" && item.id == projectId
  );

  if (data?.data) {
    try {
      teammates = data?.data?.map((teammate) => {
        if (!teammate.id) {
          throw new Error("Missing team.id");
        }
        if (!teammate?.attributes?.["name"]) {
          throw new Error("Missing team.attributes.name");
        }

        return {
          id: teammate?.id?.toString(),
          name: teammate?.attributes?.["name"],
          variables:
            (teammate?.attributes?.["project-variables"] as any[]) || [],
          userId: teammate?.["relationships"]?.user?.data?.id?.toString(),
        };
      });

      if (!data.meta) {
        throw new Error("Missing data.meta");
      }

      if (!data.meta["record-count"]) {
        throw new Error('Missing data.meta["record-count"]');
      }

      if (!data.meta["page-count"]) {
        throw new Error('Missing data.meta["page-count"]');
      }
      pageMeta = {
        recordCount: data.meta["record-count"],
        pageCount: data.meta["page-count"],
      };
    } catch (err) {
      if (err instanceof Error) {
        customError = err.message;
      } else {
        customError = "An unknown error occurred";
      }
    }
  }

  return {
    teammates,
    project,
    isLoading,
    error: error ?? customError,
    pageMeta,
    refetch,
    isFetching,
  };
};
export default useListCollaborators;
