import { useAuthorization } from "@common/providers";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { useGetAccountQuery, useGetProjectByIdQuery, useWhoAmIQuery } from "@generated";
import React from "react";
import { Edit } from "./Edit";
import { Route, Routes } from "react-router";
import Show from "./Show/Show";

function ProjectVersionsV2Mspa({ account_slug, project_id }) {
  // Get Account
  const {
    data: account,
    error: accountError,
    isLoading: accountLoading,
    refetch: accountRefetch,
  } = useGetAccountQuery({
    slug: account_slug,
    include: ["phases", "resources"],
  });

  // Get permissions for current user
  const {
    data: currentUser,
    error: currentUserError,
    isLoading: currentUserLoading,
    refetch: currentUserRefetch,
  } = useWhoAmIQuery();

  const {
    data: project,
    error: projectError,
    isLoading: projectLoading,
    refetch: projectRefetch,
  } = useGetProjectByIdQuery({ slug: account_slug, id: project_id });

  const permission = currentUser?.data?.attributes?.privileges;
  const auditLogPermission: string =
    permission?.find(
      (privilege) => privilege.privilege === "projects.versions"
    )?.["access-level"] || "";

  const authorized = useAuthorization();
  if (
    !authorized ||
    !currentUser ||
    currentUserLoading ||
    !project ||
    projectLoading
  ) {
    return <ScopeStackSpinner />;
  }
  return (
    <div className="tab-content">
      <div className="tab-pane active">
        <Routes>
          <Route
            path="/"
            element={
              <Show
                auditLogPermission={auditLogPermission}
                projectLoading={projectLoading}
                currentUserLoading={currentUserLoading}
                project={project}
                account={account?.data}
              />
            }
          />
          <Route
            path="/:versionId/edit"
            element={
              <Edit auditLogPermission={auditLogPermission} project={project} account={account?.data} />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default ProjectVersionsV2Mspa;
