import DataTable from "@components/DataTable";
import SlideOutMenuV2 from "@components/SlideOutMenuV2";
import {
  useListProjectsForService,
  useListQuestionnairesForService,
  useListBlueprintsForService,
} from "../../api";
import { useState, useEffect } from "react";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirstLetter } from "@utils/helperFunctions";

interface ServiceUsedInSlideoutProps {
  serviceId: string;
  setServiceId: (id: string) => void;
  slideoutOpen: boolean;
  setSlideoutOpen: (isOpen: boolean) => void;
}

const ServiceUsedInSlideout = ({
  serviceId,
  setServiceId,
  slideoutOpen,
  setSlideoutOpen,
}: ServiceUsedInSlideoutProps) => {
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const [projectsCurrentPage, setProjectsCurrentPage] = useState<number>(1);
  const [questionnairesCurrentPage, setQuestionnairesCurrentPage] =
    useState<number>(1);
  const [blueprintsCurrentPage, setBlueprintsCurrentPage] = useState<number>(1);

  const { projects, projectsPageMeta, projectsLoading, projectsFetching } =
    useListProjectsForService({
      serviceId,
      currentPage: projectsCurrentPage,
    });
  const {
    questionnaires,
    questionnairesPageMeta,
    questionnairesLoading,
    questionnairesFetching,
  } = useListQuestionnairesForService({
    serviceId,
    currentPage: questionnairesCurrentPage,
  });
  const {
    blueprints,
    blueprintsPageMeta,
    blueprintsLoading,
    blueprintsFetching,
  } = useListBlueprintsForService({
    serviceId,
    currentPage: blueprintsCurrentPage,
  });

  const getColumns = (type: "project" | "blueprint" | "survey") => {
    let path = `${appHost}/projects`;
    if (type === "blueprint") path = `${appHost}/admin/blueprints`;
    if (type === "survey") path = `${appHost}/surveys`;
    return [
      {
        id: `${type}Name`,
        header: `${capitalizeFirstLetter(type)} Name`,
        cell(props) {
          const item = props.row.original;
          return item.id !== "0" ? (
            <div
              className={"usedInItemName"}
              onClick={() =>
                window.open(
                  `${appHost}/${type !== "project" ? "admin/" : ""}${
                    type === "survey" ? "questionnaires" : type + "s"
                  }/${item.id}/edit`,
                  "_blank"
                )
              }
            >
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="openIcon"
              />
              {item?.name || ""}
            </div>
          ) : (
            item.name
          );
        },
      },
    ];
  };

  useEffect(() => {
    if (!slideoutOpen) {
      setServiceId("");
      setProjectsCurrentPage(1);
      setBlueprintsCurrentPage(1);
      setQuestionnairesCurrentPage(1);
    }
  }, [slideoutOpen]);

  return (
    <SlideOutMenuV2
      title="Service Used In"
      isOpen={slideoutOpen}
      onClose={() => setSlideoutOpen(false)}
    >
      <div className="servicePreviewSlideout">
        <>
          <div className="previewSubheader">Projects</div>
          {projectsFetching ? (
            <div className={!projectsLoading ? "loadingPlaceholder" : ""}>
              <SmallSpinner />
            </div>
          ) : (
            <DataTable
              data={
                projects.length
                  ? projects
                  : [{ id: "0", name: "No projects found" }]
              }
              columns={getColumns("project")}
              striped
              bordered
              hover
              paginationEnabled={projectsPageMeta.pageCount > 1}
              currentPage={projectsCurrentPage}
              setCurrentPage={setProjectsCurrentPage}
              totalPages={projectsPageMeta.pageCount}
              totalRows={projectsPageMeta.recordCount}
            />
          )}
        </>
        <>
          <div className="previewSubheader">Blueprints</div>
          {blueprintsFetching ? (
            <div className={!blueprintsLoading ? "loadingPlaceholder" : ""}>
              <SmallSpinner />
            </div>
          ) : (
            <DataTable
              data={
                blueprints.length
                  ? blueprints
                  : [{ id: "0", name: "No blueprints found" }]
              }
              columns={getColumns("blueprint")}
              striped
              bordered
              hover
              paginationEnabled={blueprintsPageMeta.pageCount > 1}
              currentPage={blueprintsCurrentPage}
              setCurrentPage={setBlueprintsCurrentPage}
              totalPages={blueprintsPageMeta.pageCount}
              totalRows={blueprintsPageMeta.recordCount}
            />
          )}
        </>
        <>
          <div className="previewSubheader">Surveys</div>
          {questionnairesFetching ? (
            <div className={!questionnairesLoading ? "loadingPlaceholder" : ""}>
              <SmallSpinner />
            </div>
          ) : (
            <DataTable
              data={
                questionnaires.length
                  ? questionnaires
                  : [
                      {
                        id: "0",
                        name: "No surveys found",
                      },
                    ]
              }
              columns={getColumns("survey")}
              striped
              bordered
              hover
              paginationEnabled={questionnairesPageMeta.pageCount > 1}
              currentPage={questionnairesCurrentPage}
              setCurrentPage={setQuestionnairesCurrentPage}
              totalPages={questionnairesPageMeta.pageCount}
              totalRows={questionnairesPageMeta.recordCount}
            />
          )}
        </>
      </div>
    </SlideOutMenuV2>
  );
};

export default ServiceUsedInSlideout;
