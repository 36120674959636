import { useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import SurveyTabs from "./SurveyTabs/SurveyTabs";
import TakeASurvey from "./TakeSurvey/TakeASurvey";
import { useAuthorization } from "../../common/providers/AuthorizationProvider";
import { RootState } from "@reducers/rootReducer";
import CreateProjectFromRecommendations from "./CreateProjectFromRecommendations/CreateProjectFromRecommendations";

function SurveyMspa({
}) {
  const authorized = useAuthorization();
  const accessToken = useSelector((state: RootState) => state.auth.token);
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [createProjectData, setCreateProjectData] = useState<null | any>(null);

  const isLoading = !authorized;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SurveyTabs surveysLoading={isLoading} accessToken={accessToken} />
        }
      />
      <Route
        path="/:questionnaireId/take"
        element={
          <TakeASurvey
            accessToken={accessToken}
            setCreateProjectData={(data) => setCreateProjectData(data)}
          />
        }
      />
      <Route
        path="/:surveyId/create_project"
        element={
          <CreateProjectFromRecommendations
            accessToken={accessToken}
            createProjectData={createProjectData}
          />
        }
      />
    </Routes>
  );
}

export default SurveyMspa;
