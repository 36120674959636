import React, { useState, useEffect, useCallback, useRef } from "react";
import OnboardingCards from "../reusable/OnboardingCards";
import filePencilCheckBg from "../images/filePencilCheckBg.png";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import BaseTable from "@components/Tables/BaseTable/BaseTable";
import ScopeStackTable from "@components/Tables/ScopeStackTable";
import { FormCheck } from "react-bootstrap";
import PaginationNumbersAPI from "@components/Buttons/PaginationAPI/PaginationNumbersAPI";
import { useNavigate } from "react-router";
import API from "@API";

function StandardsComponent({
  selectedStandards,
  setSelectedStandards,
  account_slug,
  authorizationCode,
  postStandardsAlert,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;

  const PAGE_SIZE = 10;
  const [standards, setStandards] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState({});
  const [uploadingServices, setUploadingServices] = useState(false);
  const [meta, setMeta] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  let navigate = useNavigate();

  useEffect(() => {
    if (!authorizationCode) return;

    API.Get(`${apiHost}/v1/me`, authorizationCode).then((response) => {
      API.Patch(
        `${apiHost}/${account_slug}/v1/users/${response.data.data.id}`,
        {
          data: {
            id: response.data.data.id,
            type: "users",
            attributes: {
              "guided-onboarding": {
                onboarding_status: "standard-services",
              },
            },
          },
        },
        authorizationCode
      ).then((response) => {
        // console.log(response);
      });
    });
  }, [authorizationCode]);

  const loadCatalogServices = async ({
    authorizationCode,
    pageSize,
    pageNumber,
  }) => {
    const url = new URL(`${apiHost}/v1/catalog-items`);
    url.searchParams.append("page[size]", pageSize);
    url.searchParams.append("page[number]", pageNumber);

    try {
      const response = await API.Get(url.toString(), authorizationCode);
      return {
        data: response.data.data,
        error: null,
        meta: response.data.meta,
      };
    } catch (error) {
      return { data: null, error };
    }
  };

  const fetchData = useCallback(async () => {
    if (!isLoading) {
      return;
    }

    const data = await loadCatalogServices({
      authorizationCode,
      pageSize: PAGE_SIZE,
      pageNumber: currentPage,
    });

    const pageArray = (() => {
      const pageArrayToReturn = [];
      if (data.meta) {
        for (let i = 1; i <= data.meta["page-count"]; i += 1) {
          pageArrayToReturn.push(i);
        }
      } else {
        pageArrayToReturn.push(1);
      }
      return pageArrayToReturn;
    })();
    setStandards(data.data ? data.data : []);
    setIsLoading(false);
    setMeta(data.meta || { "page-count": 1 });
    setPageArray(pageArray);
  }, [isLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const expandedStates = useRef({});
  const toggleExpansion = (id) => {
    expandedStates.current[id] = !expandedStates.current[id];
    setPageArray([...pageArray]); // Trigger re-render
  };

  const renderDescription = (id, description) => {
    if (!description || description.length <= 115) {
      return <>{description}</>;
    }

    if (expandedStates.current[id]) {
      return (
        <>
          {description}{" "}
          <button className="viewAll" onClick={() => toggleExpansion(id)}>
            -
          </button>
        </>
      );
    } else {
      return (
        <>
          {description.slice(0, 115)}...{" "}
          <button className="viewAll" onClick={() => toggleExpansion(id)}>
            +
          </button>
        </>
      );
    }
  };

  const getTableData = (standards) => {
    if (!standards || standards.length === 0) {
      return [];
    }
    return standards.map((standard, i) => {
      const standardName = standard.attributes.name || "";
      let standardDescription =
        standard.attributes["service-description"] || "";

      const isChecked = selectedStandards.some(
        (item) => item.id === standard.id
      );

      return {
        rowClass: "",
        columns: [
          {
            class: "checkbox",
            name: (
              <FormCheck
                className=""
                inline
                key={standard.name}
                value={standard.id}
                name="group1"
                type="checkbox"
                id={standard.id}
                checked={isChecked}
                onChange={(e) => {
                  const newStandards = standards.map((s) => {
                    if (s.id === e.target.id) {
                      setSelectedStandard(s);
                      return {
                        ...s,
                        checked: !s.checked,
                      };
                    }
                    return s;
                  });
                  setStandards(newStandards);
                }}
              />
            ),
          },
          {
            class: "",
            name: standardName,
          },
          {
            class: "",
            name: <>{renderDescription(standard.id, standardDescription)}</>,
          },
        ],
      };
    });
  };

  const headerData = {
    rowClass: "",
    columns: [
      { class: "checkboxHeader", name: "" },
      {
        class: "",
        name: "Service",
      },
      {
        class: "",
        name: (
          <>
            Description{" "}
            <span className="servicesSelected">
              {selectedStandards.length > 0 &&
                `${selectedStandards.length} Selected`}
            </span>
          </>
        ),
      },
    ],
  };

  const standardListTable = new ScopeStackTable(
    "standards",
    headerData,
    getTableData(standards),
    null
  );

  useEffect(() => {
    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    if (
      selectedStandards.filter((item) => item.id === selectedStandard.id)
        .length > 0 &&
      !isEmpty(selectedStandard)
    ) {
      setSelectedStandards(
        selectedStandards.filter((item) => item.id !== selectedStandard.id)
      );
    } else if (!isEmpty(selectedStandard)) {
      setSelectedStandards([...selectedStandards, selectedStandard]);
    }
  }, [standards]);

  const handleApplyStandards = async () => {
    if (selectedStandards.length === 0) {
      navigate("/onboarding/complete");
    } else {
      try {
        setUploadingServices(true);
        await Promise.all(
          selectedStandards.map(async (service) => {
            let serviceData = {
              data: {
                type: "services",
                id: service.id,
              },
            };

            try {
              const response = await API.Post(
                `${apiHost}/v1/catalog-items/${service.id}/import`,
                serviceData,
                authorizationCode
              );
            } catch (error) {
              postStandardsAlert(false);
            }
          })
        );
        postStandardsAlert(true);
        navigate("/onboarding/complete");
      } catch (error) {
        postStandardsAlert(false);
      }
    }
  };

  return (
    <OnboardingCards
      contentCardHeader={"Not sure where to start?"}
      leftContent={
        <div>
          <p>
            Kickstart your scoping journey with our collection of over 50
            pre-built services.
          </p>
          <p>
            Simply choose the services to add to your account and tailor them to
            your specific needs in settings.
          </p>
          {isLoading || uploadingServices ? (
            <SmallSpinner />
          ) : (
            <>
              <BaseTable
                className="sansTable standardListTable"
                striped={true}
                hover={true}
                bordered={true}
                headerRows={standardListTable.buildHeaderRows()}
                dataRows={standardListTable.buildDataRows()}
                footerRows={standardListTable.buildFooterRows()}
                isLoading={isLoading}
              />
              <PaginationNumbersAPI
                pageCount={meta["page-count"]}
                pageArray={pageArray}
                currentPage={currentPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                leftArrowOnClick={() => {
                  if (currentPage > 1) {
                    setSelectedStandard({});
                    setCurrentPage(currentPage - 1);
                    setIsLoading(true);
                  }
                }}
                rightArrowOnClick={() => {
                  if (currentPage < meta["page-count"]) {
                    setSelectedStandard({});
                    setCurrentPage(currentPage + 1);
                    setIsLoading(true);
                  }
                }}
                itemsPerPage={PAGE_SIZE}
                onNumberClick={(_, currentPage) => {
                  setSelectedStandard({});

                  setCurrentPage(currentPage);
                  setIsLoading(true);
                }}
              />
            </>
          )}
        </div>
      }
      rightContent={
        <div>
          <img
            src={filePencilCheckBg}
            alt="caricature files with yellow check mark, clock, and green hexagon"
            style={{ width: "100%" }}
          />
        </div>
      }
      rightButtonText={"Continue"}
      rightButtonOnClick={() => handleApplyStandards()}
      skipSetupButton={true}
    />
  );
}

export default StandardsComponent;
