import React, { ReactNode, useEffect, useState } from "react";
import Select, { Props as SelectProps } from "react-select";
import { Form } from "react-bootstrap";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
const errorMessage: string = require("../../../images/error-message-white.png");

// Define the shape of the options
interface OptionType {
  value: string | number;
  label: string;
}

// Define the props based on the SelectProps generic type
interface DropdownApiProps extends SelectProps<OptionType> {
  label: ReactNode;
  className?: string;
  width?: string;
  updateRef?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  required?: boolean;
  value?: OptionType;
  defaultValue?: OptionType;
  isInvalid?: boolean;
  isDisabled?: boolean;
  getDataHook?: any;
}

const useFetchMyApi = (getDataHook, searchText) => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const [items, setItems] = useState<any[] | []>([]);
  let { data, error, isLoading, refetch } = getDataHook({
    slug: accountSlug,
    filter: { name: searchText },
  });
  useEffect(() => {
    setTimeout(() =>
      setItems(
        (data?.data || []).map((item) => {
          return {
            label: item?.attributes?.["name"] || "",
            value: item?.id?.toString() || "",
          };
        })
      )
    );
  }, [searchText]);
  if (data) {
    return (data?.data || []).map((item) => {
      return {
        label: item?.attributes?.["name"] || "",
        value: item?.id?.toString() || "",
      };
    });
  } else {
    return [items];
  }
};

const DropdownApi: React.FC<DropdownApiProps> = ({
  value,
  onChange,
  onInputChange,
  label,
  className,
  width = "100%",
  placeholder,
  required,
  defaultValue,
  isInvalid,
  onFocus,
  isDisabled = false,
  getDataHook,
}) => {
  const [searchText, setSearchText] = useState("");
  const options = useFetchMyApi(getDataHook, searchText);

  const onInputChangeFunction = (e) => {
    if (e?.length > 3) {
      setSearchText(e);
    }
    if (onInputChange) {
      onInputChange(e.target.value, {
        action: "set-value",
        prevInputValue: "",
      });
    }
  };

  return (
    <Form.Group
      className="formGroup"
      style={isInvalid ? { marginBottom: "0px" } : {}}
    >
      <Form.Label>{label}</Form.Label>
      <Select<OptionType>
        className={className}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onInputChange={(e) => onInputChangeFunction(e)}
        options={options as any[]}
        isMulti={false as any}
        required={required}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
      {required && isInvalid ? (
        <div>
          <img
            src={errorMessage}
            alt="Please fill out this field error message"
          />
        </div>
      ) : null}
    </Form.Group>
  );
};

export default DropdownApi;
