import React, { useRef, useState } from "react";
import NewProjectDetails from "../../Projects/CreateProjectMspa/NewProjectDetails/NewProjectDetails";
import { useWhoAmIQuery } from "@generated";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import "../style.css";
import SurveyResults from "../SurveyResults/SurveyResults";
import API from "../../../utils/API/API";
import newSurvey from "../SurveyHelpers/newSurvey";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import {
  handleAlert,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";

function CreateProjectFromRecommendations({
  accessToken,
  createProjectData,
}) {
  const navigate = useNavigate();
  const locationHook = useLocation();
  const submitRef = useRef<HTMLInputElement>();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const location = window.location.href;
  var locationArr = location.split("/");
  var id = locationArr[locationArr.length - 2];

  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;

  const { data: account, isLoading: accountLoading } = useWhoAmIQuery();

  const [showFailAlert, setShowFailAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");

  if (accountLoading) {
    return <ScopeStackSpinner />;
  }

  const handleApplySurveys = async (projectId) => {
    if (!projectId) {
      return;
    }
    const survey = await newSurvey({
      questionnaire: createProjectData.questionnaire.data,
      responses: createProjectData.surveyResponses,
      account,
      surveyId: createProjectData.currentSurvey.data
        ? createProjectData.currentSurvey.data.id
        : null,
      accessToken,
      projectId,
    });

    if (account && account.data) {
      API.Put(
        `${apiHost}/${account.data?.attributes["account-slug"]}/v1/surveys/${survey.data.id}/apply`,
        {},
        accessToken
      )
        .then((response) => {
          window.location.replace(`${appHost}/projects/${projectId}/edit`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessages(formatUnprocessibleResponse(error, "project"));
          setShowFailAlert(true);
        });
    }
  };

  return (
    <main className="takeASurveyPage">
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      <Row className="flexCenter">
        <Col sm={6} className="takeSurveyName">
          {createProjectData.questionnaire.data.attributes.name}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="whiteBg takeASurveyOuterCard">
            <Card.Header className="cardHeaderOverride takeASurveyCardHeader">
              <Row className="flexCenter">
                <Col sm={1}>
                  <Button
                    className="btnSeawhite"
                    onClick={() => {
                      if (currentPage === 1) {
                        navigate(
                          `/surveys/${createProjectData.questionnaire.data.id}/take`,
                          { state: { projectData: createProjectData } }
                        );
                      } else {
                        setCurrentPage(1);
                      }
                    }}
                    variant="outline-secondary"
                  >
                    Back
                  </Button>
                </Col>
                <Col sm={8}>
                  {currentPage === 1
                    ? "Create Project From Recommendations"
                    : "Add Services From Recommendations"}
                </Col>
                <Col sm={3}>
                  <Button
                    variant="btn-secondary"
                    className="btnSeafoam pull-right"
                    onClick={() => {
                      if (currentPage === 1) {
                        const projectForm = Array.from(
                          document.getElementsByClassName("projectForm")
                        )[0];
                        //@ts-ignore
                        if (!projectForm?.checkValidity()) {
                          //@ts-ignore
                          if (projectForm?.reportValidity) {
                            //@ts-ignore
                            projectForm?.reportValidity();
                          } else {
                            //warn IE users somehow
                            setErrorMessages(
                              "Please enter all required information to continue."
                            );
                            setShowFailAlert(true);
                          }
                        } else {
                          setCurrentPage(2);
                        }
                      } else {
                        setLoading(true);
                        submitRef.current?.click();
                      }
                    }}
                    disabled={loading}
                  >
                    {currentPage == 1 ? "Continue" : "Apply and Create Project"}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <hr />
            <Card.Body>
              {loading ? (
                <ScopeStackSpinner />
              ) : (
                <>
                  <div className="createProjectSubtitle">
                    {currentPage === 1
                      ? "1. Add Client and Project Details."
                      : "2. Review & apply recommendations."}
                  </div>
                  <div style={currentPage === 1 ? {} : { display: "none" }}>
                    <NewProjectDetails
                      authorizationCode={accessToken}
                      account_id={
                        account && account.data
                          ? account.data.attributes["account-id"]
                          : null
                      }
                      account_slug={
                        account && account.data
                          ? account.data.attributes["account-slug"]
                          : null
                      }
                      source="surveys"
                      submitRef={submitRef}
                      handleApplySurveys={(projId) =>
                        handleApplySurveys(projId)
                      }
                      setCurrentSurveyPage={(page) => {
                        setCurrentPage(page);
                      }}
                      save_to_cloud_storage_flag={false}
                    />
                  </div>
                  {currentPage === 2 ? (
                    <SurveyResults
                      recommendations={createProjectData.recommendations}
                      nonServiceRecommendations={
                        createProjectData.nonServiceRecommendations
                      }
                      showRecommendations={true}
                      setShowRecommendations={() => null}
                      account_slug={
                        account && account.data
                          ? account.data.attributes["account-slug"]
                          : null
                      }
                      resources={createProjectData.resources}
                      phases={createProjectData.phases}
                      currentSurvey={createProjectData.currentSurvey}
                      calculations={createProjectData.calculations}
                      recalculateClicked={false}
                      revertClicked={false}
                      hasRecommendationsInAccordion={true}
                      hasCalculationsInAccordion={true}
                      hasStatusBanner={false}
                      surveyResponses={createProjectData.surveyResponses}
                      hasSurveyResponsesInAccordion={true}
                      accessToken={accessToken}
                      calculationsAtTop={false}
                    />
                  ) : null}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </main>
  );
}

export default CreateProjectFromRecommendations;
