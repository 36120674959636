import { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Stack } from "react-bootstrap";
import { useStyles } from "./styles";
import { Global, css } from "@emotion/react";
import {
  faBusinessTime,
  faBarsFilter,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import { faPercent } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@components/Button";
import {
  useGetTotals,
  useListRecentProjects,
  useConditionalLandingPageQuery,
  getUpsellText,
} from "./api";
import DataTable from "@components/DataTable/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Project, SubCardType } from "./types";
import AtAGlance, { AtAGlanceRef } from "./AtAGlance";
import useGetPlanName from "@common/hooks/useGetPlanName";
import usePrivilegeCheck from "@common/hooks/usePrivilegeCheck";
import ScopeStackSpinner from "@components/ScopeStackSpinner/ScopeStackSpinner";
import getFontAwesomeIcon from "@utils/getFontAwesomeIcon";
import { useWhoAmIQuery, useGetAccountQuery } from "@generated";
import { RootState } from "@reducers/rootReducer";
import { useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";

const Dashboard = (): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const styles = useStyles();
  const {
    userViewRefetch,
    refetch: refetchRecentProjects,
    data: recentProjects,
    isLoading: projectIsLoading,
    isFiltered: recentProjectsIsFiltered,
  } = useListRecentProjects();
  const {
    totals,
    isLoading: totalsIsLoading,
    refetch: refetchTotals,
  } = useGetTotals();
  const { planName, isLoading: planIsLoading } = useGetPlanName();
  const { data: pageContents, isLoading: pageContentsIsLoading } =
    useConditionalLandingPageQuery();
  const { data: me } = useWhoAmIQuery();
  const { data: account, isLoading: accountLoading } = useGetAccountQuery({
    slug: accountSlug,
  });

  const atAGlanceRef = useRef<AtAGlanceRef | null>(null);
  const [upsellText, setUpsellText] = useState<string | null>(null);
  const { hasPrivilege } = usePrivilegeCheck();
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const recentProjectsColumns: ColumnDef<Project>[] = [
    {
      header: "Project Name",
      cell(props) {
        const redirectToEditProject = () => {
          return window.location.replace(
            `${appHost}/projects/${props.row.original.id}/edit`
          );
        };
        return (
          <span
            onClick={() => redirectToEditProject()}
            style={{
              marginLeft: "5px",
              textDecoration: "underline",
              color: "#418172",
            }}
          >
            {props.row.original.name}
            <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faPencil} />
          </span>
        );
      },
    },
    {
      header: "Client Name",
      cell(props) {
        return props.row.original.clientName;
      },
    },
  ];

  const openAtAGlance = () => {
    atAGlanceRef.current?.open();
  };
  const handleFilterCountChange = (count: number) => {
    setActiveFilterCount(count);
  };

  useEffect(() => {
    const text = getUpsellText(planName);
    setUpsellText(text);
  }, [planName]);

  const onCloseAtAGlance = () => {
    userViewRefetch();
    refetchRecentProjects();
    refetchTotals();
  };

  const currencyUnit =
    account?.data?.attributes?.["field-labels"]?.["currency_unit"] ?? "$";

  return (
    <>
      <Global
        styles={css`
          body {
            background-color: #eee;
          }
        `}
      />
      <div className={styles.wrapper}>
        <Row>
          <Col>
            {me?.data?.attributes?.name && (
              <h1 className={styles.title}>
                Welcome, {me.data.attributes.name}
              </h1>
            )}
          </Col>
          <hr className={styles.line}></hr>
        </Row>
        <Row>
          <Col>
            <Card className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  <FontAwesomeIcon
                    className={styles.cardIcon}
                    icon={faPercent}
                  />
                  <h2 id="summary-header">Your Work at a Glance</h2>
                </div>
                <div className={styles.cardIconClickable}>
                  <FontAwesomeIcon
                    className={styles.cardIcon}
                    icon={faBarsFilter}
                    onClick={openAtAGlance}
                  />
                  {activeFilterCount > 0 && (
                    <div className={styles.activeCount}>
                      {activeFilterCount} Active
                    </div>
                  )}
                </div>
              </div>
              {totalsIsLoading || accountLoading ? (
                <ScopeStackSpinner />
              ) : (
                <div className={styles.cardBody}>
                  <div className={styles.glanceContainer}>
                    <div className={styles.cardGlance}>
                      <div className={styles.tableHeader}>
                        Total revenue scoped
                      </div>
                      <h1>
                        <CurrencyFormat
                          displayType="text"
                          prefix={currencyUnit}
                          isNumericString
                          thousandSeparator
                          value={(Number(totals.totalRevenue) || 0).toFixed(2)}
                        />
                      </h1>
                    </div>
                    <div className={styles.cardGlance}>
                      <div className={styles.tableHeader}>
                        Average revenue per project
                      </div>
                      <h1>
                        <CurrencyFormat
                          displayType="text"
                          prefix={currencyUnit}
                          isNumericString
                          thousandSeparator
                          value={(Number(totals.averageRevenue) || 0).toFixed(
                            2
                          )}
                        />
                      </h1>
                    </div>
                    <div className={styles.cardGlance}>
                      <div className={styles.tableHeader}>
                        Average margin per project
                      </div>
                      <h1>
                        {new Intl.NumberFormat("en-US", {
                          style: "percent",
                          minimumFractionDigits: 2,
                        }).format(totals.averageMargin)}
                      </h1>
                    </div>
                  </div>
                </div>
              )}
              <hr className={styles.line}></hr>
            </Card>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col>
            <Card className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  <FontAwesomeIcon
                    className={styles.cardIcon}
                    icon={faBusinessTime}
                  />
                  <h2>Recent Projects</h2>
                </div>
              </div>
              <hr className={styles.line}></hr>
              <div className={styles.cardBody}>
                {projectIsLoading ? (
                  <ScopeStackSpinner />
                ) : recentProjectsIsFiltered && !recentProjects.length ? (
                  <div className={styles.cardActionButtons}>
                    <h3>No projects found with the current filters.</h3>
                    <p>
                      Adjust your filters to see more projects, or reset them to
                      view all projects.
                    </p>
                  </div>
                ) : !recentProjects.length ? (
                  <div className={styles.cardActionButtons}>
                    <h3>Looks like you don’t have any recent projects.</h3>
                    <p>
                      Get started by creating a new project or exploring sample
                      projects.
                    </p>
                    <div className={styles.cardActionButtonsGroup}>
                      {hasPrivilege("projects.overview", ["view", "none"]) ? (
                        <></>
                      ) : (
                        <Button to="/projects/new" variant="primary">
                          Create Project
                        </Button>
                      )}
                      <Button to="/projects" variant="secondary">
                        View Sample Projects
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <DataTable
                      data={recentProjects}
                      columns={recentProjectsColumns}
                      striped
                    />
                    <div className={styles.recentProjectButtonWrapper}>
                      {hasPrivilege("projects.overview", ["view", "none"]) ? (
                        <></>
                      ) : (
                        <Button to="/projects/new">Create New Project</Button>
                      )}
                      <Button to="/projects" variant="secondary">
                        View All Projects
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Card>
          </Col>
          <Col>
            {pageContentsIsLoading || !pageContents?.data ? (
              <ScopeStackSpinner />
            ) : (
              <>
                {planName !== "ScopeStack-Essentials-Plan-USD-Monthly" &&
                  pageContents.data.filter(
                    (item: { attributes?: { "content-type"?: string } }) =>
                      item.attributes &&
                      item.attributes["content-type"] === "Master Class"
                  ).length > 0 && (
                    <Card className={styles.card}>
                      <div className={styles.cardHeader}>
                        <div className={styles.cardTitle}>
                          <FontAwesomeIcon
                            className={styles.cardIcon}
                            icon={faAngleRight}
                          />
                          <h2>ScopeStack Masterclass</h2>
                        </div>
                      </div>
                      <hr className={styles.line}></hr>
                      {pageContents.data
                        .filter(
                          (item: {
                            attributes?: { "content-type"?: string };
                          }) =>
                            item.attributes &&
                            item.attributes["content-type"] === "Master Class"
                        )
                        .map((item, index) => (
                          <a
                            key={index}
                            href={item.attributes?.link}
                            className={styles.nextSteps}
                            target={
                              item &&
                              item.attributes &&
                              item.attributes.link &&
                              item.attributes.link.includes(
                                "https://scopestack.io"
                              )
                                ? ""
                                : "_blank"
                            }
                          >
                            <div>
                              {getFontAwesomeIcon({
                                className: styles.cardIconCircled,
                                iconClass: item.attributes?.icon,
                              })}
                            </div>
                            <div>
                              <h2>{item.attributes?.title}</h2>
                              <p>{item.attributes?.content}</p>
                            </div>
                          </a>
                        ))}
                    </Card>
                  )}

                {!planIsLoading && !planName?.includes("Premium") && (
                  <Card className={styles.card}>
                    <div className={styles.cardHeader}>
                      <div className={styles.cardTitle}>
                        <FontAwesomeIcon
                          className={styles.cardIcon}
                          icon={faAngleRight}
                        />
                        <h2>Your Next Steps</h2>
                      </div>
                    </div>
                    <hr className={styles.line}></hr>
                    {pageContents.data
                      .filter(
                        (item: { attributes?: { "content-type"?: string } }) =>
                          item.attributes &&
                          item.attributes["content-type"] === "Next Steps"
                      )
                      .map((item, index) => (
                        <a
                          key={index}
                          href={item.attributes?.link}
                          className={styles.nextSteps}
                          target={
                            item &&
                            item.attributes &&
                            item.attributes.link &&
                            item.attributes.link.includes(
                              "https://scopestack.io"
                            )
                              ? ""
                              : "_blank"
                          }
                        >
                          <div>
                            {getFontAwesomeIcon({
                              className: styles.cardIconCircled,
                              iconClass: item.attributes?.icon,
                            })}
                          </div>
                          <div>
                            <h2>{item.attributes?.title}</h2>
                            <p>{item.attributes?.content}</p>
                          </div>
                        </a>
                      ))}
                  </Card>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
      <AtAGlance
        ref={atAGlanceRef}
        onClose={onCloseAtAGlance}
        onFilterCountChange={handleFilterCountChange}
      />
    </>
  );
};

export default Dashboard;
