import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import CardHeader from "@components/CardHeader/CardHeader";
import ControlledDropDown from "@components/Forms/ControlledDropDown";
import BackButton from "@components/Buttons/BackButton/BackButton";
import API from "@API";
import { useLocation } from "react-router-dom";
import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import {
  removeDuplicates,
  formatUnprocessibleResponse,
} from "@utils/helperFunctions";
import DismissibleAlert from "@components/Alerts/DismissibleAlert";

function NewApproval({
  account_slug,
  project_id,
  account_id,
  versions,
  projectApprovals,
  approvalSteps,
  approvalStepId,
  users,
  authorizationCode,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  let location = useLocation();

  const [newApprover, setNewApprover] = useState("");
  const [newApproverId, setNewApproverId] = useState(null);
  const [required, setRequired] = useState("");
  const [lobs, setLobs] = useState([]);

  const [showFailAlert, setShowFailAlert] = useState("");
  const [errorMessages, setErrorMessages] = useState("");

  useEffect(() => {
    if (authorizationCode !== "") {
      // Get lines of business
      API.Get(
        `${apiHost}/${account_slug}/v1/line-of-businesses`,
        authorizationCode
      ).then((response) => {
        let data = response.data.data;
        if (data !== null)
          setLobs(
            data.map((lob) => {
              return { id: lob.id, checked: false, name: lob.attributes.name };
            })
          );
      });
    }
  }, [authorizationCode, users]);

  let userArray = [];
  let usersWithApprovals = new Set();
  const projectApprovalsOnApprovalStep = projectApprovals.filter(
    (pa) => pa.relationships["approval-step"].data.id == approvalStepId
  );
 
  projectApprovalsOnApprovalStep.forEach((approval) => {
    users.forEach((user) => {
      let userRelationship = approval.relationships.user.data;
      if (userRelationship && userRelationship.id == user.id) {
        usersWithApprovals.add(user);
      }
    });
  });
  const nonDuplicatedUsersWithApprovals = Array.from(usersWithApprovals);

  userArray = users.filter(
    (user) =>
      !nonDuplicatedUsersWithApprovals.some(
        (usersWithApprovals) => user.id == usersWithApprovals.id
      )
  );

  const userOptions = userArray.map((user) => {
    return (
      <option accessKey={user.id} key={user.id}>
        {user.attributes.name}
      </option>
    );
  });
  userOptions.unshift(<option key="blank"></option>);

  const handleSubmit = (e) => {
    e.preventDefault();

    let newApprovalData = {
      data: {
        type: "project-approvals",
        attributes: {
          status: required.toLowerCase(),
          "lob-ids": lobs
            .map((lob) => {
              if (lob.checked === true) {
                return lob.id;
              } else {
                return null;
              }
            })
            .filter((lob) => lob !== null),
        },
        relationships: {
          "approval-step": {
            data: {
              type: "approval-steps",
              id: location.state.approvalStepId,
            },
          },
          user: {
            data: { type: "users", id: newApproverId },
          },
        },
      },
    };

    API.Post(
      `${apiHost}/${account_slug}/v1/project-approvals`,
      newApprovalData,
      authorizationCode
    )
      .then((response) => {
        window.location.replace(
          `${appHost}/projects/${project_id}/project_approvals`
        );
      })
      .catch((err) => {
        setErrorMessages(formatUnprocessibleResponse(err, "Approver"));
        setShowFailAlert(true);
      });
  };

  const handleFailAlert = () => {
    if (showFailAlert === true) {
      return (
        <DismissibleAlert
          variant="warning"
          onClose={() => setShowFailAlert(false)}
          text={errorMessages}
        />
      );
    }
  };

  return (
    <div>
      {handleFailAlert()}
      <Card>
        <CardHeader title="Request Additional Approval" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <ControlledDropDown
                  label="Additional Approval Required From *"
                  value={newApprover}
                  onChange={(e) => {
                    let selected = e.target.options.selectedIndex;
                    setNewApprover(e.target.value);
                    setNewApproverId(e.target.options[selected].accessKey);
                  }}
                  options={userOptions}
                />
              </Col>
              <Col>
                <Row>
                  {location.state.approvalStep === "technical_approval" ? (
                    <Col>
                      <FormFieldLabel label="Lines of Business to Approve" />
                      <div>
                        {lobs.map((lob) => (
                          <Form.Check
                            checked={lob.checked}
                            key={lob.id}
                            id={lob.id}
                            label={lob.name}
                            name="group1"
                            onChange={(e) =>
                              setLobs(
                                lobs.map((lob) => {
                                  if (lob.id == e.target.id) {
                                    return { ...lob, checked: !lob.checked };
                                  } else {
                                    return lob;
                                  }
                                })
                              )
                            }
                          />
                        ))}
                      </div>
                    </Col>
                  ) : null}
                  <Col
                    sm={
                      location.state.approvalStep !== "technical_approval"
                        ? 4
                        : 6
                    }
                  >
                    <ControlledDropDown
                      label="Required/Optional *"
                      value={required}
                      onChange={(e) => setRequired(e.target.value)}
                      options={[
                        <option key={null}></option>,
                        <option key="required">Required</option>,
                        <option key="optional">Optional</option>,
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: "2%" }}>
              <Col xs={6}>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </Col>
              <Col xs={6}>
                <BackButton url={`/projects/${project_id}/project_approvals`} />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default NewApproval;
