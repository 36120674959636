import DataTable from "@components/DataTable";
import {
  useCreateProjectCollaboratorMutation,
  useDeleteProjectCollaboratorMutation,
  useUpdateProjectCollaboratorMutation,
} from "@generated";
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Teammate } from "./types/Teammate";
import { ColumnDef } from "@tanstack/react-table";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import { handleAlert } from "@utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import ProjectVariablesV2 from "../ProjectVariables/ProjectVariablesV2";
import "./style/style.css";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import { Select2V2 } from "@components/FormsV2";
import EditableDataTable from "@components/EditableDataTable";
import useFlags from "@common/hooks/useFlags";

function CollaboratorsV2Table({
  project_id,
  account_slug,
  teammatesPassed,
  projectPassed,
  refetch,
  usersPassed,
  currentUserPassed,
  allProjectVariablesPassed,
}) {
  const [deleteProjectTeammate] = useDeleteProjectCollaboratorMutation();
  const [updateTeammate] = useUpdateProjectCollaboratorMutation();
  const [createTeammate] = useCreateProjectCollaboratorMutation();

  //States
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [teammateIdToDelete, setTeammateIdToDelete] =
    useState<number | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedTeammate, setSelectedTeammate] = useState({
    label: "Select teammate to add to project",
    value: "0",
  });
  const [teammates, setTeammates] = useState(teammatesPassed);
  const [project, setProject] = useState(projectPassed);
  const [users, setUsers] = useState(usersPassed);
  const [currentUser, setCurrentUser] = useState(currentUserPassed);
  const [allProjectVariables, setAllProjectVariables] = useState(
    allProjectVariablesPassed
  );
  const [allFlags, setAlLFlags] = useState(useFlags());

  const deleteTeammate = (id) => {
    deleteProjectTeammate({ slug: account_slug, id: id })
      .unwrap()
      .then((response) => {
        setShowDeleteModal(false);
        refetch().then((res) => {
          setTeammates(
            res.data.data.map((teammate) => {
              return {
                id: teammate?.id?.toString(),
                name: teammate?.attributes?.["name"],
                variables:
                  (teammate?.attributes?.["project-variables"] as any[]) || [],
                userId: teammate?.["relationships"]?.user?.data?.id?.toString(),
              };
            })
          );
        });
        setSuccessMessage("Teammate removed from project!");
        setShowSuccessAlert(true);
      })
      .catch((err) => {
        setShowDeleteModal(false);
        const error = err?.data?.error;
        setErrorMessage(
          `Teammate could not be removed due to ${error} error! Please try again later.`
        );
        setShowFailAlert(true);
      });
  };

  const savePvs = (teammate, pv) => {
    const variables = teammate?.variables.filter(
      (variable) => variable.name !== pv.name
    );
    if (teammate) {
      const data = {
        id: teammate?.id,
        type: "project-collaborators",
        attributes: {
          "project-variables": variables.concat([pv]),
        },
        relationships: {
          project: { data: { id: project_id, type: "projects" } },
          user: { data: { type: "users", id: teammate?.userId } },
        },
      };
      // @ts-ignore
      updateTeammate({
        slug: account_slug,
        // @ts-ignore
        body: { data: data },
        id: Number(teammate?.id),
      })
        .unwrap()
        .then((res) => {
          setSuccessMessage("Teammate updated!");
          setShowSuccessAlert(true);
        })
        .catch((err) => {
          const error = err?.data?.error;
          setErrorMessage(
            `Teammate could not be updated due to ${error} error! Please try again later.`
          );
          setShowFailAlert(true);
        });
    }
  };

  const addTeammate = (e) => {
    const foundTeammate = teammates.find(
      (t) => t.userId == selectedTeammate?.value
    );
    if (foundTeammate) {
      setErrorMessage("Teammate already exists on project!");
      setShowFailAlert(true);
      return;
    }
    const data = {
      type: "project-collaborators",
      attributes: {},
      relationships: {
        project: { data: { id: project_id, type: "projects" } },
        user: { data: { type: "users", id: selectedTeammate?.value } },
      },
    };
    createTeammate({
      slug: account_slug,
      // @ts-ignore
      body: { data: data },
    })
      .unwrap()
      .then((res) => {
        setSuccessMessage("Teammate added to project!");
        setShowSuccessAlert(true);
        setShowAddModal(false);
        refetch().then((res) => {
          setTeammates(
            res.data.data.map((teammate) => {
              return {
                id: teammate?.id?.toString(),
                name: teammate?.attributes?.["name"],
                variables:
                  (teammate?.attributes?.["project-variables"] as any[]) || [],
                userId: teammate?.["relationships"]?.user?.data?.id?.toString(),
              };
            })
          );
        });
      })
      .catch((err) => {
        const error = err?.data?.error;
        setErrorMessage(
          `Teammate could not be added due to ${error} error! Please try again later.`
        );
        setShowFailAlert(true);
        setShowAddModal(false);
      });
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    setSelectedTeammate({
      label: "Select teammate to add to project",
      value: "0",
    });
  };

  const renderTeammateOptions = () => {
    return (
      users?.data?.map((user) => {
        return {
          label: user?.attributes?.name,
          value: user?.id?.toString(),
        };
      }) || []
    );
  };
  const teammatePermission = currentUser?.attributes?.privileges?.find(
    (p) => p.privilege == "projects.collaborators"
  )?.accessLevel;

  const getTeammateColumns = () => {
    console.log(allFlags);
    let teammatesColumns: any[] = [
      {
        header() {
          return "Teammates";
        },
        cell(teammate: Teammate) {
          return <div>{teammate.name}&nbsp;</div>;
        },
      },
      {
        header() {
          return " ";
        },
        cell(teammate: Teammate) {
          return (
            <div>
              {teammatePermission &&
                teammatePermission === "manage" &&
                project?.attributes?.["status"] === "building" && (
                  <FontAwesomeIcon
                    style={{ color: "#FD0300" }}
                    icon={faTrashCan}
                    onClick={() => {
                      setTeammateIdToDelete(Number(teammate.id));
                      setShowDeleteModal(true);
                    }}
                  />
                )}
            </div>
          );
        },
        cellClass: "text-right",
      },
    ];

    if (allProjectVariables?.length > 0) {
      const pvColumns = allProjectVariables?.map((pv) => {
        return {
          header() {
            return pv?.required ? pv?.label + " *" : pv?.label;
          },
          cell(teammate: Teammate) {
            const projectVariable = teammate.variables?.find(
              (variable) => variable.name == pv?.name
            );
            return (
              <div>
                <ProjectVariablesV2
                  pvArrPassed={projectVariable ? [projectVariable] : [pv]}
                  context="teammate"
                  requiredOnly={false}
                  unrequiredOnly={false}
                  accountSlug={account_slug}
                  hasSectionTitle={false}
                  hasTopBorder={false}
                  hasGreyBox={false}
                  has3PerRow={false}
                  isInDataTable={true}
                  onBlur={(projVariable) => {
                    const variables =
                      teammate.variables?.filter(
                        (v) => v?.name !== projVariable?.name
                      ) || [];
                    teammate.variables = [...variables, projVariable];
                    savePvs(teammate, projVariable);
                  }}
                />
              </div>
            );
          },
        };
      });
      teammatesColumns = [
        teammatesColumns[0],
        // @ts-ignore
        ...pvColumns,
        // @ts-ignore
        teammatesColumns[1],
      ];
    }

    return teammatesColumns;
  };

  if (teammatePermission && teammatePermission === "none") {
    return (
      <div>
        You do not have permmission to view this page. Please contact your
        administrator for assistance.
      </div>
    );
  }

  return (
    <div className="collaboratorsV2Page">
      <Card className="whiteBg details">
        {handleAlert(
          showSuccessAlert,
          successMessage,
          setShowSuccessAlert,
          "success",
          ToastAlert
        )}
        {handleAlert(
          showFailAlert,
          errorMessage,
          setShowFailAlert,
          "warning",
          ToastAlert
        )}
        <Card.Body>
          <Card.Header className="cardHeaderOverride">
            <div className="headerContainer">
              <div className="actionBtns">
                <h2>
                  <span className="text24">Assign Teammates</span>
                </h2>
              </div>
              <div className="actionBtns">
                {teammatePermission &&
                  teammatePermission !== "view" &&
                  project?.attributes?.["status"] === "building" && (
                    <Button
                      className="btnSeafoam"
                      type="button"
                      onClick={() => {
                        setShowAddModal(true);
                      }}
                      disabled={false}
                    >
                      Add Teammates
                    </Button>
                  )}
              </div>
            </div>
          </Card.Header>

          <EditableDataTable
            config={{ striped: true, hover: true, bordered: true }}
            records={
              teammates?.length > 0
                ? teammates
                : [
                    {
                      id: "noRow",
                      name: "No teammates found",
                    },
                  ]
            }
            columns={getTeammateColumns()}
            // selectable={false}
            // striped={true}
            // selectedIds={[]}
            // setSelectedIds={() => null}
            // dragAndDrop={false}
            // isCollapsible={false}
            // hasSettings={false}
          />
        </Card.Body>
        <ConfirmationModal
          show={showDeleteModal}
          title={"Delete Teammate"}
          message={`Are you sure you want to delete this teammate?`}
          onConfirm={() => deleteTeammate(teammateIdToDelete)}
          onCancel={() => setShowDeleteModal(false)}
        />
        <ScopeStackModal
          className={""}
          modalTitle={"Add Teammate"}
          modalBody={
            <Select2V2
              label={"Select Teammate"}
              options={renderTeammateOptions()}
              isDisabled={false}
              onChange={(e) => {
                setSelectedTeammate(e);
              }}
              value={selectedTeammate}
              placeholder={"Select teammate to add to project"}
            />
          }
          button1Text="Cancel"
          handleButton1Click={() => closeAddModal()}
          button2Text={"Add Teammate"}
          handleButton2Click={(e) => addTeammate(e)}
          button2Disabled={selectedTeammate?.value == "0"}
          show={showAddModal}
          handleClose={() => closeAddModal()}
        />
      </Card>
    </div>
  );
}

export default CollaboratorsV2Table;
