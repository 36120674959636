import React, { ChangeEvent, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";

interface TextFieldProps {
  label: string | null;
  id: string;
  placeholder?: string;
  value?: string | null | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  updateRef?: boolean;
  className?: string;
  onBlur?: (e: any) => void;
  defaultValue?: string | null | undefined;
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  id,
  placeholder,
  value,
  onChange,
  required,
  readOnly,
  disabled,
  updateRef,
  className,
  defaultValue,
  onBlur,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (updateRef && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [updateRef]);

  return (
    <Form.Group className={`formGroup ${className ? className : ""}`}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        required={required}
        id={id}
        value={value === null ? "" : value}
        placeholder={placeholder}
        onChange={onChange}
        type="text"
        readOnly={readOnly}
        disabled={disabled}
        ref={inputRef}
        className="textFieldV2"
        onBlur={onBlur ? onBlur : () => null}
        defaultValue={defaultValue || ""}
      />
    </Form.Group>
  );
};

export default TextField;
